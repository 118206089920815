import { PickersDay } from '@mui/x-date-pickers';
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableCell,
  TableContainer,
  TablePagination,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';

const IncreasedSpecificity = '&&';

const colorPalette = {
  white: '#FFFFFF',
  black: '#000000',
  gray1: '#FAFAFA',
  gray2: '#C2C2C2',
  gray3: '#EAEAEA',
  gray4: '#ADADAD',
  gray5: '#757575',
  gray6: '#424242',
  gray7: '#232323',
  blue1: '#E3F2FD',
  blue2: '#BBDEFB',
  blue3: '#90CAF9',
  blue4: '#2196F3',
  blue5: '#1976D2',
  blue6: '#0D47A1',
  magenta1: '#FFAFD2',
  magenta2: '#FFD6E8',
  magenta3: '#FFAFD2',
  magenta4: '#E2629A',
  magenta6: '#9F1853',
  bronze1: '#FCF2E6',
  bronze2: '#FAD8AF',
  bronze3: '#F5BC76',
  bronze4: '#CF7911',
  bronze6: '#8A4D03',
  teal1: '#D9FBFB',
  teal2: '#97EBEB',
  teal3: '#3AD6D4',
  teal4: '#08BDBA',
  teal6: '#005D5D',
  purple1: '#F3E5F5',
  purple2: '#E1BEE7',
  purple3: '#CE93D8',
  purple4: '#BA68C8',
  purple6: '#4A148C',
  statusRedLightest: '#F7EDED',
  statusRedLight: '#EDD3D3',
  statusRed: '#AE0000',
  warningRed: '#FF3131',
  successGreen: '#31ff31',
};

const shiftColors = {
  teal: {
    name: 'Teal',
    primary: colorPalette.teal2,
    secondary: colorPalette.teal1,
    tertiary: colorPalette.teal3,
    text: colorPalette.teal6,
    subText: colorPalette.teal4,
    icon: colorPalette.teal6,
    hover: colorPalette.teal3,
    border: `1px solid ${colorPalette.teal6}`,
  },
  bronze: {
    name: 'Bronze',
    primary: colorPalette.bronze2,
    secondary: colorPalette.bronze1,
    tertiary: colorPalette.bronze3,
    text: colorPalette.bronze6,
    subText: colorPalette.bronze4,
    icon: colorPalette.bronze6,
    hover: colorPalette.bronze3,
    border: `1px solid ${colorPalette.bronze6}`,
  },
  magenta: {
    name: 'Magenta',
    primary: colorPalette.magenta2,
    secondary: colorPalette.magenta1,
    tertiary: colorPalette.magenta3,
    text: colorPalette.magenta6,
    subText: colorPalette.magenta4,
    icon: colorPalette.magenta6,
    hover: colorPalette.magenta3,
    border: `1px solid ${colorPalette.magenta6}`,
  },
  purple: {
    name: 'Purple',
    primary: colorPalette.purple2,
    secondary: colorPalette.purple1,
    tertiary: colorPalette.purple3,
    text: colorPalette.purple6,
    subText: colorPalette.purple4,
    icon: colorPalette.purple6,
    hover: colorPalette.purple3,
    border: `1px solid ${colorPalette.purple6}`,
  },
  blue: {
    name: 'Blue',
    primary: colorPalette.blue2,
    secondary: colorPalette.blue1,
    tertiary: colorPalette.blue3,
    text: colorPalette.blue6,
    subText: colorPalette.blue4,
    icon: colorPalette.blue6,
    hover: colorPalette.blue3,
    border: `1px solid ${colorPalette.blue6}`,
  },
  light: {
    name: 'Light',
    primary: colorPalette.gray2,
    secondary: colorPalette.gray1,
    tertiary: colorPalette.gray3,
    text: colorPalette.gray6,
    subText: colorPalette.gray4,
    icon: colorPalette.gray6,
    hover: colorPalette.gray3,
    border: `1px solid ${colorPalette.gray6}`,
  },
  dark: {
    name: 'Dark',
    primary: colorPalette.gray6,
    secondary: colorPalette.gray6,
    tertiary: colorPalette.gray5,
    text: colorPalette.gray2,
    subText: colorPalette.gray4,
    icon: colorPalette.gray3,
    hover: colorPalette.gray7,
    border: `1px solid ${colorPalette.gray6}`,
  },
};

const sharedMeasurements = {
  appBarHeight: 56,
  drawerWidth: 250,
  basicButtonRadius: 3,
  headerItemHeight: 33,
  sideBarItemTopMargin: 30,
  lightBorder: `1px solid ${colorPalette.gray2}`,
  standardBorder: `1px solid ${colorPalette.gray3}`,
  standardBorderRadius: 4,
  tableCellHeight: 40,
  innerCellHeight: 34,
  titleColumnWidth: 225,
  standardSpacing: 16,
  dataTableCellWidth: '16.66666666%',
};

// TODO: change text styles to match design
// NOTE: likely best on index.css page in body tag

const sharedStyles = {
  fillVerticalParent: {
    height: '87%',
    display: 'flex',
    flexDirection: 'column',
  },
  fillVertical: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  noWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  centerVertical: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

const headerCellStyle = {
  top: 0,
  zIndex: 4,
};
const footerCellStyle = {
  bottom: 0,
  zIndex: 4,
};

export const PrimaryActionButton = styled<any>(Button)({
  [IncreasedSpecificity]: {
    fontSize: 13,
    fontWeight: 500,
    height: 40,
    textTransform: 'none',
    color: colorPalette.white,
    backgroundColor: colorPalette.blue5,
    borderRadius: sharedMeasurements.basicButtonRadius,
  },
});

export const SecondaryActionButton = styled(PrimaryActionButton)({
  [IncreasedSpecificity]: {
    color: colorPalette.statusRed,
    backgroundColor: colorPalette.statusRedLightest,
  },
});

export const TertiaryActionButton = styled(PrimaryActionButton)({
  [IncreasedSpecificity]: {
    color: colorPalette.blue5,
    backgroundColor: colorPalette.blue1,
  },
});

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    padding: 10,
    maxHeight: sharedMeasurements.headerItemHeight,
  },
});

export const StyledTableContainer = styled(TableContainer)({
  marginTop: sharedMeasurements.standardSpacing,
  border: sharedMeasurements.standardBorder,
  borderRadius: sharedMeasurements.standardBorderRadius,
  backgroundColor: colorPalette.white,
  '&::-webkit-scrollbar': {
    display: 'auto',
  },
});

export const DataTableContainer = styled(StyledTableContainer)({
  height: '93%',
  textAlign: 'left',
});

export const PaginationTableContainer = styled(TableContainer)({
  height: '7%',
});

export const StyledTable = styled(Table)({
  '& .MuiTableCell-sizeSmall': {
    padding: 0,
  },
  borderCollapse: 'separate',
});

const BasicTableCell = styled(TableCell)({
  boxSizing: 'border-box',
  height: sharedMeasurements.tableCellHeight,
});

export const StyledTableCell = styled(BasicTableCell)({
  border: sharedMeasurements.lightBorder,
});

export const StickyTableCell = styled(StyledTableCell)({
  position: 'sticky',
});

export const StickyHeaderCell = styled(StickyTableCell)(headerCellStyle);
export const StickyFooterCell = styled(StickyTableCell)(footerCellStyle);

export const DataTableCell = styled(BasicTableCell)({
  width: sharedMeasurements.dataTableCellWidth,
});

export const DataTableHeader = styled(DataTableCell)({
  backgroundColor: colorPalette.gray2,
});

export const StyledTablePagination = styled(TablePagination)({
  marginBottom: sharedMeasurements.standardSpacing,
  borderBottom: 'none',
  minHeight: 20,
  overflow: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const DataTableCheckBox = styled(Checkbox)({
  marginLeft: 10,
});

export const DataTableLeftText = styled(Box)({
  [IncreasedSpecificity]: {
    marginLeft: '7px',
  },
});

export const DataTableRightText = styled(Box)({
  [IncreasedSpecificity]: {
    marginRight: '14px',
  },
});

export const AppSpacerBox = styled(Box)({
  marginTop: sharedMeasurements.appBarHeight + sharedMeasurements.standardSpacing / 2,
});

export const FillVerticalBox = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const CustomDayPicker = styled(PickersDay)({
  [`${IncreasedSpecificity}.Mui-selected`]: {
    backgroundColor: colorPalette.blue5,
    color: colorPalette.white,
  },
});

// NOTE: unused parameters are still sent by renderDay function.  required
export const renderCustomDayPicker = (date, selectedDates, pickersDayProps) => (
  <CustomDayPicker
    {...pickersDayProps}
    aria-label={date.toLocaleString(DateTime.DATE_MED)}
    disableMargin
  />
);

export const getDateTimePaperPropsStyles = (timePickerView?: string) => {
  const hideArrowViews = ['hours', 'minutes', 'seconds'];

  const sxProps = {
    '.MuiDayPicker-weekContainer': {
      justifyContent: 'space-evenly',
    },
    '.MuiDayPicker-header': {
      justifyContent: 'space-evenly',
    },
  };

  if (hideArrowViews.includes(timePickerView)) {
    sxProps['.MuiPickersArrowSwitcher-root'] = {
      display: 'none',
    };
  }

  return {
    sx: sxProps,
  };
};

export {
  IncreasedSpecificity,
  sharedStyles,
  colorPalette,
  shiftColors,
  sharedMeasurements,
  headerCellStyle,
  footerCellStyle,
};
