import { ExpandMore } from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { unstable_batchedUpdates } from 'react-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { drawerState, pageLoadingState } from '../atoms/PageState';
import {
  allLocationsState,
  currentLocationState,
  hideUnscheduledState,
  pageNumberState,
  queryStringState,
  searchBarState,
} from '../atoms/QueryState';
import { deliveryMethodFilterState, scheduleViewFilterState } from '../atoms/ShiftState';
import config from '../config';
import { i18Prefix } from '../i18Constants';
import '../i18n';
import { getAllLocations, getDetailedLocations } from '../services/location';
import { postBlockList } from '../services/ops';
import { userAccess } from '../utilities/AccessPermissions';
import {
  colorPalette,
  IncreasedSpecificity,
  PrimaryActionButton,
  sharedMeasurements,
  shiftColors,
  AppSpacerBox,
} from '../utilities/Styles';
import { classifications, pageState, scheduleViews, tempFeatureFlag } from '../utilities/Variables';

const sendBlockListTestId = 'sendBlockList';
const uploadBlockListTestId = 'uploadBlockList';
const draggedUploadBlockListTestId = `${uploadBlockListTestId}-dragging`;
const fileUploadErrorTestId = 'fileUploadError';
const fileUploadMessageTestId = 'fileUploadMessage';
const drawerTestId = 'drawer';
const locationSearchTestId = 'locationSearch';

const basicFormStyles = {
  marginTop: sharedMeasurements.sideBarItemTopMargin,
  minWidth: '80%',
};
const checkedFormStyles = {
  '&$checked': {
    color: !colorPalette.blue5,
    background: 'none',
  },
};
const expandedFormStyles = {
  ...basicFormStyles,
  ...checkedFormStyles,
  checked: {},
  maxWidth: '80%',
};
const accordionRootBasicStyles = {
  boxShadow: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&$expanded': {
    margin: 'auto',
    minHeight: 0,
  },
};

const StyledDivider = styled(Box)({
  ...basicFormStyles,
  borderBottom: sharedMeasurements.lightBorder,
  width: '100%',
});

const BottomBox = styled(StyledDivider)({
  [IncreasedSpecificity]: {
    borderBottom: 'none',
    height: 75,
    width: '100%',
    position: 'absolute',
    bottom: '0%',
  },
});

const CollapseButton = styled(PrimaryActionButton)({
  [IncreasedSpecificity]: {
    position: 'absolute',
    bottom: '17.5%',
    left: '7.5%',
    paddingLeft: '21%',
    paddingRight: '21%',
    borderRadius: 10,
  },
});

const BottomTabButton = styled(Box)({
  zIndex: 1200,
  position: 'fixed',
  bottom: '6.5%',
  left: 0,
  width: 32,
  height: 32,
});

const StyledSelectFormControl = styled(FormControl)({
  [IncreasedSpecificity]: basicFormStyles,
});
const StyledInputFormControl = styled(StyledSelectFormControl)({
  [IncreasedSpecificity]: expandedFormStyles,
});
const StyledInputFormControlLabel = styled(FormControlLabel)({
  [IncreasedSpecificity]: {
    ...expandedFormStyles,
    minWidth: 0,
  },
});

const StyledAccordion = styled(Accordion)({
  [IncreasedSpecificity]: {
    marginTop: sharedMeasurements.sideBarItemTopMargin - 10,
    root: {
      ...accordionRootBasicStyles,
      height: 40,
      minHeight: 0,
    },
    expanded: {},
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  [IncreasedSpecificity]: {
    root: {
      ...accordionRootBasicStyles,
    },
    expanded: {},
  },
});

const StyledCheckbox = styled(Checkbox)({
  [IncreasedSpecificity]: {
    root: {
      ...checkedFormStyles,
      '&&:hover': {
        backgroundColor: 'transparent',
      },
    },
    checked: {},
  },
});

const StyledMenuItem = styled(MenuItem)({
  [IncreasedSpecificity]: {
    padding: '6px 16px 6px 16px',
    height: 36,
    display: 'flex',
    justifyContent: 'flex-start',
  },
});

const SideBar = (props) => {
  // NOTE: for testing
  const { mockFile } = props;

  const queryString = useRecoilValue(queryStringState);
  const setPageNumber = useSetRecoilState(pageNumberState);
  const queryParams = new URLSearchParams(queryString);
  const queryLocation = queryParams.get('location');
  const { authState } = useOktaAuth();

  const [drawerVisibility, setDrawerVisibility] = useRecoilState(drawerState);
  const [pageLoading, setPageLoading] = useRecoilState(pageLoadingState);
  const [currentLocation, setCurrentLocation] = useRecoilState(currentLocationState);
  const [allLocations, setAllLocations] = useRecoilState(allLocationsState);
  const [deliveryMethodFilter, setDeliveryMethodFilter] =
    useRecoilState<any>(deliveryMethodFilterState);
  const [scheduleViewFilter, setScheduleViewFilter] = useRecoilState(scheduleViewFilterState);
  const [hideUnscheduled, setHideUnscheduled] = useRecoilState(hideUnscheduledState);
  const [recoilSearchBarText, setRecoilSearchBarText] = useRecoilState(searchBarState);
  const [apiError, setApiError] = useState(undefined);
  const [searchBarText, setSearchBarText] = useState(''); // local to component
  const [timeoutId, setTimeoutId] = useState(undefined);
  const [blockListFile, setBlockListFile] = useState(mockFile || undefined);
  const [fileUploadMessage, setFileUploadMessage] = useState(undefined);
  const [fileUploading, setFileUploading] = useState(undefined);
  const [dragging, setDragging] = useState(false);

  const blockedListUploadAccess = userAccess.blockListUpload;
  const hasBlockedListUploadAccess = (
    Array.isArray(authState?.accessToken?.claims?.userType)
      ? authState?.accessToken?.claims?.userType
      : []
  ).some((userType) => blockedListUploadAccess.indexOf(userType as any) >= 0);

  const handleSearchChange = (event) => {
    setSearchBarText(event.target.value); // updates the text in the search box
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(
        // timeout to prevent choppiness when users are typing quickly
        () => {
          setRecoilSearchBarText(event.target.value); // updates the result set
        },
        500,
      ), // wait 500 ms to ensure the user has finished typing
    );
  };

  useEffect(() => {
    // clear local searchBarState on page change
    if (recoilSearchBarText === '') setSearchBarText('');
  }, [recoilSearchBarText]);

  const { t } = useTranslation();

  /* Fetch locations from api and set initial currentLocation based on the
  query string if it exists, or first result in dataset by default */
  useEffect(() => {
    if (allLocations.length) return;
    async function updateLocations() {
      try {
        const locationsData = await getAllLocations();
        const selectedLocation =
          locationsData.find((loc) => (loc.displayName || loc.name) === queryLocation) ||
          locationsData[0];
        const selectedLocationInfo = await getDetailedLocations([selectedLocation.id]);
        unstable_batchedUpdates(() => {
          setAllLocations(locationsData);
          setCurrentLocation(selectedLocationInfo[0]);
        });
      } catch (error) {
        console.log(error);
        setApiError(error.message || t(`${i18Prefix}.api_failure`));
      }
    }
    updateLocations();
  }, []);

  const toggleDrawer = (isDrawerVisible) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerVisibility(isDrawerVisible);
  };

  const changeLocation = async (event, value) => {
    setPageLoading(pageState.loading);
    setPageNumber(0);
    const selectedLocationInfo = await getDetailedLocations([value.id]);
    setCurrentLocation(selectedLocationInfo[0]);
  };

  const changeDeliveryMethodFilter = (event) => {
    if (pageLoading !== pageState.done) return;
    setDeliveryMethodFilter({
      ...deliveryMethodFilter,
      [event.target.name]: event.target.checked,
    });
  };

  const changeScheduleViewFilter = (event) => {
    setScheduleViewFilter(event.target.value);
  };

  const getDisplayName = (option) =>
    option.id !== '' ? `${option.id} - ${option.displayName || option.name}` : '';

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragFinish = (event) => {
    event.preventDefault();
    setDragging(false);
  };

  const acceptBlockList = (files) => {
    const fileUploaded = files[0];
    setBlockListFile(fileUploaded);
  };

  const sendBlockList = async () => {
    try {
      setFileUploading(true);
      const response = await postBlockList(blockListFile);

      if (response.success === true)
        setFileUploadMessage(t(`${i18Prefix}.upload_block_list_success`));
      setFileUploading(false);
      setBlockListFile(undefined);
    } catch (error) {
      console.log(error);
      if (error.message === 'Request canceled') return;

      setFileUploading(false);
      setBlockListFile(undefined);
      setFileUploadMessage(error.message || t(`${i18Prefix}.api_failure`));
    }
  };

  const cancelBlockList = () => {
    setFileUploadMessage(undefined);
    setBlockListFile(undefined);
  };

  const linkToSchedulerFeedback = () => {
    openInNewTab('https://forms.gle/YkuqkgGngs8xi3359');
  };

  const linkToForecastingFeedback = () => {
    openInNewTab(
      'https://docs.google.com/forms/d/17e5-InKCWgRuwz5CKPp2GHdShg0UdWOf2nU_GCrNlfs/viewform?edit_requested=true',
    );
  };

  const generateSelect = (text, dict) => (
    <StyledInputFormControl>
      <TextField
        select
        id={text}
        defaultValue='0'
        {...({ disablunderline: 'true' } as any)}
        SelectProps={{
          native: true,
        }}
      >
        <option key='disabled' value='0' disabled>
          {text}
        </option>
        {Object.keys(dict).map((key) => (
          <option key={key} value={dict[key].value}>
            {dict[key].name}
          </option>
        ))}
      </TextField>
    </StyledInputFormControl>
  );

  const sideBarList = () => (
    <Box
      style={{
        width: sharedMeasurements.drawerWidth,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid container direction='row' justifyContent='center' alignContent='space-between'>
        <StyledInputFormControl>
          <Autocomplete
            id='Location'
            data-testid={locationSearchTestId}
            disableClearable
            {...({ variant: 'outlined' } as any)}
            value={currentLocation.id ? currentLocation : { id: '' }}
            onChange={changeLocation}
            options={allLocations.length ? allLocations : [{ id: ' ' }]}
            getOptionLabel={getDisplayName}
            isOptionEqualToValue={(option: any, value) => option.id === value.id || value.id === ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(`${i18Prefix}.location`)}
                variant='outlined'
                error={apiError ? true : null}
                helperText={apiError || null}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        </StyledInputFormControl>

        <StyledDivider />

        <TextField
          label={t(`${i18Prefix}.search`)}
          margin='dense'
          variant='outlined'
          style={{
            width: '80%',
            marginTop: '16px',
          }}
          size='small'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchBarText}
          onChange={handleSearchChange}
        />

        <StyledAccordion
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
            },
          }}
        >
          <StyledAccordionSummary
            expandIcon={<ExpandMore />}
            data-testid='deliveryMethodFiltersList'
          >
            {t(`${i18Prefix}.filter_by_delivery_type`)}
          </StyledAccordionSummary>
          <AccordionDetails
            sx={{
              [IncreasedSpecificity]: {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            <FormGroup>
              <FormControlLabel
                disabled={pageLoading !== pageState.done}
                control={
                  <StyledCheckbox
                    checked={deliveryMethodFilter.car}
                    onChange={changeDeliveryMethodFilter}
                    name='car'
                    data-testid='deliveryMethod_car'
                  />
                }
                label={t(`${i18Prefix}.motor_vehicle`)}
              />
              <FormControlLabel
                disabled={pageLoading !== pageState.done}
                control={
                  <StyledCheckbox
                    checked={deliveryMethodFilter.bike}
                    onChange={changeDeliveryMethodFilter}
                    name='bike'
                    data-testid='deliveryMethod_bike'
                  />
                }
                label={t(`${i18Prefix}.bike`)}
              />
              <FormControlLabel
                disabled={pageLoading !== pageState.done}
                control={
                  <StyledCheckbox
                    checked={deliveryMethodFilter.foot}
                    onChange={changeDeliveryMethodFilter}
                    name='foot'
                    data-testid='deliveryMethod_foot'
                  />
                }
                label={t(`${i18Prefix}.walker`)}
              />
              {config.DISPLAY_MOPED && (
                <FormControlLabel
                  disabled={pageLoading !== pageState.done}
                  control={
                    <StyledCheckbox
                      checked={deliveryMethodFilter.moped}
                      onChange={changeDeliveryMethodFilter}
                      name='moped'
                      data-testid='deliveryMethod_moped'
                    />
                  }
                  label={t(`${i18Prefix}.moped`)}
                />
              )}
            </FormGroup>
          </AccordionDetails>
        </StyledAccordion>

        <StyledSelectFormControl variant='outlined'>
          <InputLabel id='schedule-view-label'>{t(`${i18Prefix}.schedule_view`)}</InputLabel>
          <Select
            MenuProps={
              {
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              } as any
            }
            id='scheduleView'
            labelId='schedule-view-label'
            label={t(`${i18Prefix}.schedule_view`)}
            value={scheduleViewFilter}
            onChange={changeScheduleViewFilter}
          >
            {scheduleViews.map((view) => (
              <StyledMenuItem className={view} value={view} key={view}>
                {t(`${i18Prefix}.${view}`)}
              </StyledMenuItem>
            ))}
          </Select>
        </StyledSelectFormControl>

        <StyledInputFormControlLabel
          control={
            <StyledCheckbox
              onChange={(e) => {
                setHideUnscheduled(e.target.checked);
              }}
              checked={hideUnscheduled}
              name='Hide Unscheduled'
            />
          }
          label={t(`${i18Prefix}.hide_unscheduled`)}
        />

        <StyledDivider />

        {hasBlockedListUploadAccess && (
          <>
            <StyledInputFormControl>
              {blockListFile && (
                <>
                  <PrimaryActionButton
                    variant='contained'
                    component='label'
                    onClick={sendBlockList}
                    data-testid={sendBlockListTestId}
                  >
                    {fileUploading && (
                      <CircularProgress
                        size={25}
                        style={{
                          margin: 'auto',
                        }}
                      />
                    )}
                    {!fileUploading &&
                      `${t(`${i18Prefix}.send`)} ${
                        blockListFile.name.length >= 18
                          ? `...${blockListFile.name.slice(blockListFile.name.length - 15)}`
                          : blockListFile.name
                      }`}
                  </PrimaryActionButton>
                  <PrimaryActionButton
                    variant='contained'
                    component='label'
                    style={{ marginTop: 10 }}
                    onMouseUp={cancelBlockList}
                  >
                    {t(`${i18Prefix}.cancel`)}
                  </PrimaryActionButton>
                </>
              )}
              {!blockListFile && (
                <>
                  <Dropzone onDrop={(acceptedFiles) => acceptBlockList(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <input
                          {...getInputProps({
                            accept: '.csv',
                          })}
                        />
                        <Tooltip
                          title={<h3>{t(`${i18Prefix}.drag_to_upload`)}</h3>}
                          placement='top'
                        >
                          <PrimaryActionButton
                            {...getRootProps({
                              component: 'label',
                              variant: 'contained',
                              onDragOver: handleDragOver,
                              onDragLeave: handleDragFinish,
                              onDrop: handleDragFinish,
                            })}
                            data-testid={
                              dragging ? `${uploadBlockListTestId}-dragging` : uploadBlockListTestId
                            }
                            style={{
                              border: `2px dashed ${
                                dragging ? colorPalette.successGreen : colorPalette.gray4
                              }`,
                            }}
                          >
                            {t(`${i18Prefix}.upload_block_list`)}
                          </PrimaryActionButton>
                        </Tooltip>
                      </>
                    )}
                  </Dropzone>
                </>
              )}
              {fileUploadMessage && (
                <span
                  data-testid={
                    fileUploadMessage === t(`${i18Prefix}.upload_block_list_success`)
                      ? fileUploadMessageTestId
                      : fileUploadErrorTestId
                  }
                  style={{
                    color:
                      fileUploadMessage === t(`${i18Prefix}.upload_block_list_success`)
                        ? colorPalette.successGreen
                        : colorPalette.warningRed,
                    fontSize: '12px',
                    marginTop: 6,
                  }}
                >
                  {fileUploadMessage}
                </span>
              )}
            </StyledInputFormControl>

            <StyledDivider />
          </>
        )}

        <StyledInputFormControl>
          <Link
            component='button'
            variant='body2'
            onKeyUp={linkToSchedulerFeedback}
            onClick={linkToSchedulerFeedback}
          >
            {t(`${i18Prefix}.scheduling_page_feedback`)}
          </Link>
          <Link
            component='button'
            variant='body2'
            onKeyUp={linkToForecastingFeedback}
            onClick={linkToForecastingFeedback}
            style={{ marginTop: 20 }}
          >
            {t(`${i18Prefix}.forecasting_feedback`)}
          </Link>
        </StyledInputFormControl>

        {tempFeatureFlag && generateSelect('Filter by Positions', classifications)}
        {tempFeatureFlag && generateSelect('Filter by Color', shiftColors)}

        <BottomBox>
          <Box style={{ width: '100%', height: '100%', position: 'relative' }}>
            <CollapseButton onClick={toggleDrawer(false)}>
              {t(`${i18Prefix}.collapse_sidebar`)}
            </CollapseButton>
          </Box>
        </BottomBox>
      </Grid>
    </Box>
  );

  return (
    <BottomTabButton position='relative'>
      <IconButton
        onClick={toggleDrawer(true)}
        size='small'
        style={{
          borderRadius: '0px 4px 4px 0px',
          backgroundColor: colorPalette.gray2,
          boxShadow:
            '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
        }}
      >
        <ArrowForwardIosIcon style={{ color: colorPalette.blue5 }} />
      </IconButton>

      <Drawer
        data-testid={drawerTestId}
        variant='persistent'
        open={drawerVisibility}
        BackdropProps={{ invisible: true }}
        style={{
          zIndex: 899,
          overflow: 'hidden',
        }}
      >
        <AppSpacerBox />
        {sideBarList()}
      </Drawer>
    </BottomTabButton>
  );
};

export {
  fileUploadErrorTestId,
  fileUploadMessageTestId,
  sendBlockListTestId,
  uploadBlockListTestId,
  draggedUploadBlockListTestId,
  drawerTestId,
  locationSearchTestId,
};
export default SideBar;
