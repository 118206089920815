import {
  AppBar,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ExploreIcon from '@mui/icons-material/Explore';
import Help from '@mui/icons-material/Help';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { styled } from '@mui/material/styles';
import { pageLoadingState } from '../atoms/PageState';
import { colorPalette, sharedMeasurements } from '../utilities/Styles';
import { faqURL, pages, pageState, tempFeatureFlag } from '../utilities/Variables';
import { queryStringState } from '../atoms/QueryState';

const faqTestId = 'faq';

const appBarIconWidth = 45;

const flexStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: appBarIconWidth,
});

const FlexContainer = styled(Container)(flexStyle);
const FlexList = styled<any>(List)(flexStyle);

const AppHeader = () => {
  const queryString = useRecoilValue(queryStringState);
  const location = useLocation();
  const setPageLoading = useSetRecoilState(pageLoadingState);

  const handleClick = (page) => location.pathname === page || setPageLoading(pageState.loading);

  return (
    <AppBar
      position='fixed'
      elevation={1}
      style={{
        zIndex: 9001,
        backgroundColor: colorPalette.gray3,
      }}
    >
      <Toolbar
        disableGutters
        style={
          {
            '&& .spacedLinks': {
              display: 'flex',
              justifyContent: 'space-between',
            },
          } as any
        }
      >
        <FlexContainer className='spacedLinks' maxWidth={false}>
          <FlexList className='spacedLinks' component='nav' aria-labelledby='main navigation'>
            {Object.keys(pages).map((page) => (
              <Link
                key={pages[page].route}
                to={`${pages[page].route}${queryString.toString()}`}
                style={{
                  textDecoration: 'none',
                }}
              >
                <ListItem
                  data-testid={`button${pages[page].display}`}
                  button
                  onClick={() => handleClick(pages[page].route)}
                  style={{
                    marginRight: sharedMeasurements.standardSpacing,
                  }}
                >
                  <StyledListItemIcon
                    style={{
                      color:
                        location.pathname === pages[page].route
                          ? colorPalette.gray6
                          : colorPalette.gray4,
                    }}
                  >
                    {pages[page].title() === pages.scheduler.title() && (
                      <EventNoteIcon fontSize='large' />
                    )}
                    {pages[page].title() === pages.deliveryPartners.title() && (
                      <PermContactCalendarIcon fontSize='large' />
                    )}
                    {pages[page].title() === pages.locations.title() && (
                      <ExploreIcon fontSize='large' />
                    )}
                  </StyledListItemIcon>
                  <ListItemText
                    style={{
                      color:
                        location.pathname === pages[page].route
                          ? colorPalette.gray6
                          : colorPalette.gray4,
                    }}
                    data-testid={pages[page].title()}
                    primary={pages[page].title()}
                  />
                </ListItem>
              </Link>
            ))}
          </FlexList>

          <List component='nav' aria-labelledby='main navigation'>
            <a
              href={`${faqURL}`}
              style={{ textDecoration: 'none' }}
              target='_blank'
              rel='noopener noreferrer'
            >
              <ListItem button>
                <StyledListItemIcon
                  style={{
                    color: location.pathname === '/faq' ? colorPalette.gray6 : colorPalette.gray4,
                  }}
                >
                  <Help fontSize='large' />
                </StyledListItemIcon>
                <ListItemText
                  style={{
                    color: location.pathname === '/faq' ? colorPalette.gray6 : colorPalette.gray4,
                  }}
                  data-testid={faqTestId}
                  primary='FAQ'
                />
              </ListItem>
            </a>

            {tempFeatureFlag && (
              <IconButton
                edge='end'
                style={{ color: colorPalette.gray4 }}
                aria-label='settings'
                size='large'
              >
                <SettingsIcon fontSize='large' />
              </IconButton>
            )}
          </List>
        </FlexContainer>
      </Toolbar>
    </AppBar>
  );
};

export { faqTestId };
export default AppHeader;
