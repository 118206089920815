import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import TRANSLATIONS_EN from './locales/en-US/translations.json';
import TRANSLATIONS_ES from './locales/es-ES/translations.json';
import TRANSLATIONS_DE from './locales/de-DE/translations.json';
import TRANSLATIONS_FR from './locales/fr-FR/translations.json';
import TRANSLATIONS_EN_CA from './locales/en-CA/translations.json';
import TRANSLATIONS_EN_GB from './locales/en-GB/translations.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en'],
    debug: false,
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
      de: {
        translation: TRANSLATIONS_DE,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      'en-CA': {
        translation: TRANSLATIONS_EN_CA,
      },
      'en-GB': {
        translation: TRANSLATIONS_EN_GB,
      },
    },
  });
