import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { modalState } from '../atoms/ModalState';
import { pageLoadingState } from '../atoms/PageState';
import {
  calendarViewState,
  currentLocationState,
  dateState,
  pageNumberState,
  peopleSortedAlphabetically,
  peopleSortedByShifts,
  peopleState,
  sortByState,
  userActionState,
  searchBarState,
} from '../atoms/QueryState';
import {
  allShiftsState,
  deliveryMethodFilterState,
  scheduleViewFilterState,
  shiftDataState,
} from '../atoms/ShiftState';
import { i18Prefix } from '../i18Constants';
import '../i18n';
import { addShiftsFromServer, getMUIHourIndex, filterShifts } from '../models/ShiftData';
import { getPeople } from '../services/people';
import { getShifts } from '../services/shifts';
import {
  formatHour,
  formatHourRange,
  formatSingleDayNoYear,
  formatTotalHours,
  getDifferenceAsHours,
  getDifferenceAsHoursVariableDisplay,
  getDifferenceAsMinutes,
  getHoursForDay,
  getDateBoundsForShiftData,
  getWeekFromDay,
  generateDateMap,
  getHoursListsForWeek,
  numDaysInWeek,
} from '../utilities/Dates';
import {
  AppSpacerBox,
  colorPalette,
  sharedStyles,
  sharedMeasurements,
  shiftColors,
  StyledTable,
  StyledTableCell,
  IncreasedSpecificity,
  StyledTableContainer,
  StyledTablePagination,
  StickyFooterCell,
  StickyHeaderCell,
  FillVerticalBox,
} from '../utilities/Styles';
import {
  calendarViews,
  modals,
  pages,
  pageState,
  paginationOptions,
  sortByOptions,
  tempFeatureFlag,
  userActions,
} from '../utilities/Variables';
import { paginationBackButtonTestId, paginationNextButtonTestId } from '../utilities/TestIds';
import PageHeader from './PageHeader';
import TitleCell, { cellTypes, tableCellViewModes } from './scheduler/TitleCell';
import config from '../config';
import { mergePeopleLists } from '../utilities/Functions';
import { unstable_batchedUpdates } from 'react-dom';

const schedulerTestId = 'scheduler';

const dataColumnWidth = 140;
const weeklyDataColumnWidth = 200;
const leftPaddingInnerCell = 12;

const dailyCellDimensionsStyle = {
  minWidth: dataColumnWidth,
  maxWidth: dataColumnWidth,
};
const weeklyCellDimensionsStyle = {
  minWidth: weeklyDataColumnWidth,
  maxWidth: weeklyDataColumnWidth,
};
const hoverableStyle = {
  '& .showOnHover': {
    display: 'none',
  },
  '&:hover .showOnHover': {
    display: 'flex',
  },
};
const headerFooterStyle = {
  backgroundColor: colorPalette.gray2,
  paddingLeft: leftPaddingInnerCell,
};
const dailyHeaderFooterStyle = {
  ...dailyCellDimensionsStyle,
  ...headerFooterStyle,
};
const weeklyHeaderFooterStyle = {
  ...weeklyCellDimensionsStyle,
  ...headerFooterStyle,
};

const StyledBox = styled(Box)({
  height: sharedMeasurements.innerCellHeight - 6,
  margin: 2,
  borderRadius: 2,
});

const StyledIconButton = styled(IconButton)({
  '&:hover, &.Mui-focusVisible': {
    borderRadius: sharedMeasurements.standardBorderRadius,
  },
});

const DailyTableCell = styled(StyledTableCell)(dailyCellDimensionsStyle);

const DailyHeaderCell = styled(StickyHeaderCell)({
  [IncreasedSpecificity]: dailyHeaderFooterStyle,
});

const DailyFooterCell = styled(StickyFooterCell)({
  [IncreasedSpecificity]: dailyHeaderFooterStyle,
});

const WeeklyTableCell = styled(StyledTableCell)(weeklyCellDimensionsStyle);

const WeeklyHeaderCell = styled(StickyHeaderCell)({
  [IncreasedSpecificity]: weeklyHeaderFooterStyle,
});

const WeeklyFooterCell = styled(StickyFooterCell)({
  [IncreasedSpecificity]: weeklyHeaderFooterStyle,
});

const HoverableBox = styled<any>(StyledBox)(hoverableStyle);
const HoverableableCell = styled(DailyTableCell)(hoverableStyle);
const { fillVerticalParent, centerVertical, noWrap } = sharedStyles;

const Scheduler = (props) => {
  const { timeoutLength } = props;

  const { t } = useTranslation();

  const [shiftData, setShiftData] = useRecoilState<any>(shiftDataState);
  const [pageLoading, setPageLoading] = useRecoilState(pageLoadingState);
  const [userAction, setUserAction] = useRecoilState(userActionState);
  const [allShifts, setAllShifts] = useRecoilState(allShiftsState);
  const [people, setPeople] = useRecoilState(peopleState);
  const calendarView = useRecoilValue(calendarViewState);
  const currentLocation = useRecoilValue(currentLocationState);
  const date = useRecoilValue(dateState);
  const deliveryMethodFilter = useRecoilValue(deliveryMethodFilterState);
  const peopleSortedByShiftsList = useRecoilValue(peopleSortedByShifts);
  const peopleSortedByAlphabet = useRecoilValue(peopleSortedAlphabetically);
  const scheduleViewFilter = useRecoilValue(scheduleViewFilterState);
  const sortBy = useRecoilValue(sortByState);
  const setDisplayModal = useSetRecoilState(modalState);
  const [apiError, setApiError] = useState(undefined);
  const [pageNumber, setPageNumber] = useRecoilState(pageNumberState);
  const setSearchBarState = useSetRecoilState(searchBarState);
  // NOTE: pagination default of 30 rows
  const [rowsPerPage, setRowsPerPage] = useState(paginationOptions[1]);

  // getting previous location id state
  const prevLocationIdRef: any = useRef();
  useEffect(() => {
    prevLocationIdRef.current = currentLocation.id;
  });
  const prevLocationId = prevLocationIdRef.current;

  const immediateAvailableShiftTableHeight = currentLocation.shiftRequestEnabled ? '20%' : '25%';
  const scheduledShiftTableHeight = currentLocation.shiftRequestEnabled ? '51%' : '66%';

  const days = getWeekFromDay(date, currentLocation.timeZone);
  const hours = getHoursForDay(date, currentLocation.timeZone);
  const hoursInWeek = getHoursListsForWeek(date, currentLocation.timeZone);
  const { shiftStartTime, shiftEndTime, dateMap } = getDateBoundsForShiftData(
    calendarView,
    date,
    days,
    currentLocation,
  );

  // refresh page after 300 seconds without user activity
  useEffect(() => {
    const timeout = setTimeout(() => {
      setUserAction({
        // updating user action state triggers the data refresh
        userAction: userActions.autoRefresh,
        actionCount: userAction.actionCount + 1,
      });
    }, timeoutLength || 300000);

    return () => clearTimeout(timeout);
  }, [currentLocation, date, calendarView, userAction]);

  // NOTE: computes how much of cell table to use for shift display
  function mapTimeDifferenceToWidth(endTime, startTime, totalTimeInMinutes) {
    const difference = getDifferenceAsMinutes(endTime, startTime) / totalTimeInMinutes;
    return `${difference * 100}%`;
  }

  useEffect(() => {
    if (!props.isTest) setSearchBarState('');
    if (!currentLocation.id) return;
    const locationChangedOrPageLoaded = prevLocationId !== currentLocation.id;
    let canceled = false; // avoid race condition
    async function fetchData() {
      try {
        // get shift data
        // get people data only if location changed or page loads
        const peopleFetch = locationChangedOrPageLoaded
          ? getPeople(currentLocation.id, null)
          : null;
        const [incomingShiftData, incomingPeopleData] = await Promise.all([
          getShifts({
            startTime: shiftStartTime.toISO(),
            endTime: shiftEndTime.toISO(),
            locationId: currentLocation.id,
          }),
          peopleFetch,
        ]);
        const filteredShiftData = filterShifts(incomingShiftData, deliveryMethodFilter);

        const shifts = addShiftsFromServer({
          filteredShiftData,
          calendarView,
          date: date.setZone(currentLocation.timeZone),
          timeZone: currentLocation.timeZone,
          dateMap,
          hoursInDay: hours,
          hoursInWeek: hoursInWeek,
        }) as any;

        // set state
        if (canceled) return;
        unstable_batchedUpdates(() => {
          setShiftData(shifts);
          setAllShifts(incomingShiftData);
          const tempPeopleList = mergePeopleLists(
            incomingPeopleData || people,
            shifts?.assignedShifts?.deliveryPartners,
          );
          setPeople(tempPeopleList);
          setPageLoading(pageState.done);
        });
      } catch (error) {
        console.log(error);
        setPageLoading(pageState.error);
        setApiError(error.message || t(`${i18Prefix}.api_failure`));
      }
    }

    fetchData();
    return () => {
      canceled = true;
    };
  }, [currentLocation, date, calendarView, userAction]);

  useEffect(() => {
    if (!allShifts.length) return;
    setPageLoading(pageState.loading);
    const filteredShiftData = filterShifts(allShifts, deliveryMethodFilter);
    const dateMap = generateDateMap(date, currentLocation);
    const { timeZone } = currentLocation;
    setShiftData(
      addShiftsFromServer({
        filteredShiftData,
        calendarView,
        date,
        timeZone,
        dateMap,
        hoursInDay: hours,
        hoursInWeek: hoursInWeek,
      }) as any,
    );
    setPageLoading(pageState.done);
  }, [deliveryMethodFilter]);

  const buildEmptyCell = (key, assignedPersonId, startTime, mustRequest = false) => (
    <HoverableableCell
      key={key}
      style={{
        minWidth:
          calendarView === calendarViews.daily.name() ? dataColumnWidth : weeklyDataColumnWidth,
        maxWidth:
          calendarView === calendarViews.daily.name() ? dataColumnWidth : weeklyDataColumnWidth,
      }}
    >
      <StyledBox
        className='showOnHover'
        style={
          {
            backgroundColor: colorPalette.gray2,
            ...noWrap,
          } as any
        }
      >
        <StyledIconButton
          size='small'
          color='inherit'
          style={{
            color: colorPalette.blue5,
            width: '100%',
          }}
          onClick={() => {
            setDisplayModal({
              open: true,
              modal: modals.createModal,
              modalObject: { shift: { assignedPersonId, startTime, mustRequest } },
            });
          }}
        >
          <AddIcon {...({} as any)} size='inherit' />
        </StyledIconButton>
      </StyledBox>
    </HoverableableCell>
  );

  const mapShiftsToCell = (connectedShiftList, cellInHeader, totalMinutes = 0) => {
    const items = [];
    const endOfDay = hours.at(-1).endOf('hour');

    connectedShiftList.map((shift, index) => {
      let secondaryColor = shiftColors.light.secondary;
      let tertiaryColor = shiftColors.light.tertiary;
      let textColor = shiftColors.light.text;
      let subTextColor = shiftColors.light.subText;
      let iconColor = shiftColors.light.icon;
      let hoverColor = shiftColors.light.hover;
      let { border } = shiftColors.light;

      if (cellInHeader) {
        secondaryColor = shiftColors.dark.secondary;
        tertiaryColor = shiftColors.dark.tertiary;
        textColor = shiftColors.dark.text;
        subTextColor = shiftColors.dark.subText;
        iconColor = shiftColors.dark.icon;
        hoverColor = shiftColors.dark.hover;
        border = shiftColors.dark.border;
      } else if ('color' in Object.keys(shift)) {
        secondaryColor = shiftColors[shift.color].secondary;
        tertiaryColor = shiftColors[shift.color].tertiary;
        textColor = shiftColors[shift.color].text;
        subTextColor = shiftColors[shift.color].subText;
        iconColor = shiftColors[shift.color].icon;
        hoverColor = shiftColors[shift.color].hover;
        border = shiftColors[shift.color].border;
      }

      let startTime = shift.startTime;
      if (startTime < hours[0]) {
        startTime = hours[0];
      }

      let endTime = shift.endTime;
      if (endTime > endOfDay) {
        endTime = startTime.plus({ minutes: totalMinutes });
      }

      const shiftLength = mapTimeDifferenceToWidth(endTime, startTime, totalMinutes);

      const shiftStartBumper =
        index !== 0
          ? mapTimeDifferenceToWidth(startTime, connectedShiftList[index - 1].endTime, totalMinutes)
          : '0%';

      let shiftBackground = '';
      let onHover = {};
      if (shift.assignedPersonId === null) {
        shiftBackground = shift.isPublished
          ? shiftColors.dark.primary
          : `repeating-linear-gradient(135deg,${shiftColors.dark.tertiary},
            ${shiftColors.dark.tertiary} 5px,${shiftColors.dark.primary} 5px,
            ${shiftColors.dark.primary} 10px)`;
        onHover = {
          backgroundColor: shiftColors.dark.hover,
        };
      } else {
        shiftBackground = shift.isPublished
          ? shiftColors.light.primary
          : `repeating-linear-gradient(135deg,${shiftColors.light.tertiary},
            ${shiftColors.light.tertiary} 5px,${shiftColors.light.primary} 5px,
            ${shiftColors.light.primary} 10px)`;
        onHover = {
          backgroundColor: shiftColors.light.hover,
        };
      }

      // NOTE: if daily view, add a spacer to left of shift if necessary
      if (calendarView === calendarViews.daily.name() && index !== 0) {
        items.push(
          <Box
            style={{
              width: shiftStartBumper,
            }}
          />,
        );
      }

      items.push(
        <Box
          key={`${shift}-${shift.startTime}-${shift.endTime}`}
          alignItems='center'
          style={
            {
              width: calendarView === calendarViews.daily.name() ? shiftLength : '100%',
              ...noWrap,
            } as any
          }
        >
          <HoverableBox
            display='flex'
            alignItems='center'
            style={{
              border,
              position: 'relative',
              background: shiftBackground,
              '&:hover': onHover,
              ...noWrap,
            }}
          >
            <Box
              component='span'
              style={{
                paddingLeft: 2,
                position: 'relative',
              }}
            >
              <Grid
                container
                wrap='nowrap'
                direction='row'
                alignItems='center'
                style={{
                  height: 24,
                  backgroundColor: secondaryColor,
                  paddingRight: 8,
                  border,
                  borderRadius: 3,
                  color: textColor,
                }}
              >
                <Grid
                  item
                  style={{
                    borderRadius: 2,
                    color: iconColor,
                  }}
                >
                  {shift.deliveryMethodId === 1 && (
                    <DirectionsCarIcon
                      fontSize='small'
                      style={{
                        color: iconColor,
                        backgroundColor: tertiaryColor,
                        marginTop: 2,
                        marginLeft: 2,
                        borderTop: border,
                      }}
                    />
                  )}
                  {shift.deliveryMethodId === 2 && (
                    <DirectionsBikeIcon
                      fontSize='small'
                      style={{
                        color: iconColor,
                        backgroundColor: tertiaryColor,
                        marginTop: 2,
                        marginLeft: 2,
                        borderTop: border,
                      }}
                    />
                  )}
                  {shift.deliveryMethodId === 3 && (
                    <DirectionsWalkIcon
                      fontSize='small'
                      style={{
                        color: iconColor,
                        backgroundColor: tertiaryColor,
                        marginTop: 2,
                        marginLeft: 2,
                        borderTop: border,
                      }}
                    />
                  )}
                  {shift.deliveryMethodId === 4 && config.DISPLAY_MOPED && (
                    <MopedOutlinedIcon
                      fontSize='small'
                      style={{
                        color: iconColor,
                        backgroundColor: tertiaryColor,
                        marginTop: 2,
                        marginLeft: 2,
                        borderTop: border,
                      }}
                    />
                  )}
                </Grid>
                <Grid item style={{ marginLeft: 5 }}>
                  {`${formatHourRange(shift.startTime, shift.endTime, currentLocation.timeZone)}`}
                </Grid>
                <Grid
                  item
                  style={{
                    color: subTextColor,
                    fontWeight: 500,
                    fontSize: 9,
                    marginTop: 3,
                    marginLeft: 5,
                  }}
                >
                  {getDifferenceAsHoursVariableDisplay(shift.endTime, shift.startTime, 0)}
                </Grid>
              </Grid>

              {shift.ids.length > 1 && (
                <Box
                  component='span'
                  style={{
                    backgroundColor: colorPalette.blue4,
                    color: colorPalette.white,
                    fontSize: 10,
                    borderRadius: '50%',
                    minHeight: 11,
                    lineHeight: '10px',
                    position: 'absolute',
                    textAlign: 'center',
                    top: -1,
                    right: -1,
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: 1,
                    paddingRight: 1,
                    marginRight: 4,
                  }}
                >
                  <Box component='span'>{shift.ids.length}</Box>
                </Box>
              )}
            </Box>

            <Box
              component='span'
              className='showOnHover'
              style={{
                zIndex: 2,
                position: 'absolute',
                right: 0,
                color: iconColor,
                backgroundColor: hoverColor,
              }}
            >
              <StyledIconButton
                size='small'
                color='inherit'
                onClick={() => {
                  setDisplayModal({
                    open: true,
                    modal: modals.editModal,
                    modalObject: { shift },
                  });
                }}
              >
                <CreateIcon {...({} as any)} size='inherit' />
              </StyledIconButton>
              {tempFeatureFlag && (
                <StyledIconButton size='small' color='inherit'>
                  <MoreHorizIcon {...({} as any)} size='inherit' />
                </StyledIconButton>
              )}
              {cellInHeader && (
                <StyledIconButton
                  size='small'
                  color='inherit'
                  onClick={() => {
                    setDisplayModal({
                      open: true,
                      modal: modals.createModal,
                      modalObject: { shift },
                    });
                  }}
                >
                  <AddIcon {...({} as any)} size='inherit' />
                </StyledIconButton>
              )}
              <Box style={{ width: 3 }} />
            </Box>
          </HoverableBox>
        </Box>,
      );

      return items;
    });

    let endBumper = '0%';
    const endTime = connectedShiftList[connectedShiftList.length - 1].endTime;
    if (endTime <= endOfDay) {
      endBumper = mapTimeDifferenceToWidth(
        endTime.startOf('hour').plus({ hours: 1 }),
        endTime,
        totalMinutes,
      );
    }

    return (
      <Box display='flex' flexDirection='row' flexWrap='nowrap' className={colorPalette.white}>
        {/* NOTE: if daily view, add a spacer to left of group if necessary */}
        <Box
          style={{
            width: mapTimeDifferenceToWidth(
              connectedShiftList[0].startTime,
              connectedShiftList[0].startTime.startOf('hour'),
              totalMinutes,
            ),
          }}
        />
        {items}
        {/* NOTE: if on the hour, no change, otherwise compute change */}
        {!connectedShiftList[connectedShiftList.length - 1].endTime.equals(
          connectedShiftList[connectedShiftList.length - 1].endTime.startOf('hour'),
        ) &&
          connectedShiftList[connectedShiftList.length - 1].endTime
            .startOf('day')
            .equals(connectedShiftList[0].startTime.startOf('day')) && (
            <Box
              style={{
                width: endBumper,
              }}
            />
          )}
      </Box>
    );
  };

  const mapShiftsToRow = (person, shiftList, rowIndex, rowInHeader, mustRequest = false) => {
    const items = [];
    let columnsSpanned = 0;
    let shiftListIndex = 0;

    if (calendarView === calendarViews.daily.name()) {
      // NOTE: if there are still columns in table, keep rendering more hours
      for (let i = 0; i < hours.length; i++) {
        if (columnsSpanned < hours.length) {
          let item = null;
          let colspan = 1;
          const connectedShifts = [];
          let getNext = true;

          // NOTE: acts as 'for loop' control for shift list
          if (shiftListIndex < shiftList.length) {
            let currentShift = shiftList[shiftListIndex];
            const currentShiftHour = currentShift.displayStartTime.startOf('hour');
            // NOTE: only grab shift if it corresponds to current hour
            if (hours[i] && currentShiftHour.equals(hours[i])) {
              // NOTE: for at least once, if more shifts exist in the list,
              // CONT: and start of next shift is same as this shift
              // CONT: append to list of connected shifts
              do {
                currentShift = shiftList[shiftListIndex];
                connectedShifts.push(currentShift);

                const nextIndex = shiftListIndex + 1;

                if (nextIndex < shiftList.length) {
                  const nextShift = shiftList[nextIndex];

                  const currentShiftEndHour = currentShift.endTime.startOf('hour');
                  const nextShiftStartHour = nextShift.displayStartTime.startOf('hour');

                  if (
                    !currentShiftEndHour.equals(nextShiftStartHour) ||
                    currentShiftEndHour.equals(currentShift.endTime)
                  ) {
                    getNext = false;
                  }
                }

                shiftListIndex += 1;
              } while (shiftListIndex < shiftList.length && getNext);

              const firstShift = connectedShifts[0];
              const lastShift = connectedShifts[connectedShifts.length - 1];

              const firstShiftStartingHour = firstShift.displayStartTime.startOf('hour');
              // NOTE: with end time, for 1:30PM, get 2PM.  for 1PM, get 1PM
              const lastShiftEndingHour = lastShift.endTime
                .startOf('hour')
                .equals(lastShift.endTime)
                ? lastShift.endTime
                : lastShift.endTime.startOf('hour').plus({ hours: 1 });
              // TODO: remove as unknown as number and fix
              let computedColspan: number = getDifferenceAsHours(
                lastShiftEndingHour,
                firstShiftStartingHour,
              ) as unknown as number;

              if (
                computedColspan <= 1 &&
                !lastShift.endTime.startOf('hour').equals(lastShift.endTime) &&
                !lastShift.endTime
                  .startOf('hour')
                  .equals(firstShift.displayStartTime.startOf('hour'))
              ) {
                computedColspan = 2;
              }

              // NOTE: compare day of start and end times of group
              // CONT: to check whether to use end of day or colspan
              colspan = computedColspan;
              colspan = Math.ceil(colspan);
              const totalMinutes = colspan * 60;

              item = (
                <DailyTableCell
                  key={`${JSON.stringify(connectedShifts)}-${rowIndex}`
                    .toLowerCase()
                    .replace(' ', '-')}
                  colSpan={colspan}
                  style={{
                    backgroundColor: rowInHeader ? 'white' : null,
                    minWidth:
                      calendarView === calendarViews.daily.name()
                        ? dataColumnWidth
                        : weeklyDataColumnWidth,
                    maxWidth:
                      calendarView === calendarViews.daily.name()
                        ? dataColumnWidth
                        : weeklyDataColumnWidth,
                  }}
                >
                  {mapShiftsToCell(connectedShifts, rowInHeader, totalMinutes)}
                </DailyTableCell>
              );

              // NOTE: update index of hours loop by number of hours used
              columnsSpanned += colspan;
              i = i + colspan - 1;
            } else {
              item = buildEmptyCell(
                `${JSON.stringify(currentShiftHour)}-${rowIndex}-${hours[i]}-${i}`
                  .toLowerCase()
                  .replace(' ', '-'),
                person?.id,
                hours[i],
                mustRequest,
              );

              columnsSpanned += colspan;
            }
          } else {
            item = buildEmptyCell(
              `${JSON.stringify(shiftListIndex)}-${rowIndex}-${hours[i]}-${i}`
                .toLowerCase()
                .replace(' ', '-'),
              person?.id,
              hours[i],
              mustRequest,
            );

            columnsSpanned += colspan;
          }
          items.push(item);
        }
      }
    } else {
      for (let i = 0; i < numDaysInWeek; i++) {
        // NOTE: if there are still columns in table, keep rendering more days
        if (columnsSpanned < numDaysInWeek) {
          let item = null;
          // NOTE: if item is neither null nor unassigned
          if (shiftList[i]) {
            item = (
              <WeeklyTableCell
                key={`${JSON.stringify(shiftList[i])}-${rowIndex}-${hours[i]}-${i}`
                  .toLowerCase()
                  .replace(' ', '-')}
                style={{
                  top: rowInHeader
                    ? sharedMeasurements.tableCellHeight * (rowIndex + 1) - 6 * rowIndex
                    : null,
                  backgroundColor: rowInHeader ? 'white' : null,
                  minWidth:
                    calendarView === calendarViews.daily.name()
                      ? dataColumnWidth
                      : weeklyDataColumnWidth,
                  maxWidth:
                    calendarView === calendarViews.daily.name()
                      ? dataColumnWidth
                      : weeklyDataColumnWidth,
                }}
              >
                {mapShiftsToCell([shiftList[i]], rowInHeader)}
              </WeeklyTableCell>
            );
            columnsSpanned += 1;
          } else {
            item = buildEmptyCell(
              `${JSON.stringify(rowInHeader)}-${rowIndex}-${hours[i]}-${i}`
                .toLowerCase()
                .replace(' ', '-'),
              person?.id,
              days[i],
              mustRequest,
            );

            columnsSpanned += 1;
          }
          items.push(item);
        }
      }
    }

    return items;
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  function listContainsShift(shiftList) {
    return shiftList.some((shift) => shift !== null);
  }

  return (
    <FillVerticalBox data-testid={schedulerTestId}>
      <AppSpacerBox />

      <PageHeader view={pages.scheduler.title()} />

      <Box
        // TODO: check better only on pageState.loading?
        style={
          {
            ...fillVerticalParent,
            ...(pageLoading !== pageState.done && centerVertical),
          } as any
        }
      >
        {pageLoading === pageState.loading && (
          <CircularProgress
            size={60}
            style={{
              margin: 'auto',
            }}
          />
        )}
        {/* TODO: display on change of table page */}
        {pageLoading === pageState.error && (
          <Card
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              color: colorPalette.statusRed,
            }}
          >
            {apiError}
          </Card>
        )}
        {pageLoading === pageState.done && (
          <FillVerticalBox>
            {(scheduleViewFilter === 'all' || scheduleViewFilter === 'immediate') && (
              <StyledTableContainer
                style={{
                  height: scheduleViewFilter === 'all' ? immediateAvailableShiftTableHeight : '91%',
                }}
              >
                <StyledTable size='small'>
                  <TableHead>
                    <TableRow>
                      <TitleCell
                        cellType={cellTypes.header}
                        mode={tableCellViewModes.available}
                        rowHasData
                        index={0}
                      />
                      {calendarView === calendarViews.daily.name() &&
                        hours.map((hour) => (
                          <DailyHeaderCell key={`sort-${hour}`}>
                            {formatHour(hour, currentLocation.timeZone)}
                          </DailyHeaderCell>
                        ))}
                      {calendarView === calendarViews.weekly.name() &&
                        days.map((day) => (
                          <WeeklyHeaderCell key={`sort-${day}`}>
                            {formatSingleDayNoYear(day, currentLocation.timeZone)}
                          </WeeklyHeaderCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* NOTE: if no shifts, render first data cell and empty cells */}
                    {shiftData.availableShifts.shifts?.length === 0 && (
                      <TableRow key={`${JSON.stringify(shiftData.availableShifts)}`}>
                        <TitleCell
                          cellType={cellTypes.data}
                          mode={tableCellViewModes.available}
                          rowHasData={false}
                          index={0}
                          hoursSum={shiftData.availableShifts.hoursSum}
                          length={shiftData.availableShifts.shifts.length}
                          totalTimeInMinutes={shiftData.availableShifts.hoursSum}
                        />
                        {mapShiftsToRow(null, [], 0, true)}
                      </TableRow>
                    )}
                    {shiftData.availableShifts.shifts.map((shiftRow, index) => (
                      <TableRow key={`${JSON.stringify(shiftRow)}-${index}`}>
                        <TitleCell
                          cellType={cellTypes.data}
                          mode={tableCellViewModes.available}
                          rowHasData
                          index={index}
                          hoursSum={shiftData.availableShifts.hoursSum}
                          length={shiftData.availableShifts.shifts.length}
                          totalTimeInMinutes={shiftData.availableShifts.hoursSum}
                        />
                        {mapShiftsToRow(null, shiftRow, index, true)}
                      </TableRow>
                    ))}
                  </TableBody>
                  {scheduleViewFilter === 'immediate' && (
                    <TableFooter>
                      <TableRow>
                        <TitleCell
                          cellType={cellTypes.footer}
                          mode={tableCellViewModes.available}
                          rowHasData
                          index={0}
                          availableHoursSum={shiftData.availableShifts.hoursSum}
                          assignedHoursSum={shiftData.assignedShifts.hoursSum}
                        />
                        {calendarView === calendarViews.daily.name() &&
                          hours.map((hour) => (
                            <DailyFooterCell
                              key={`total-${hour}`}
                              style={{
                                color: colorPalette.black,
                              }}
                            >
                              <Grid container direction='column' alignItems='center'>
                                <Grid
                                  item
                                  style={{
                                    borderBottom: '1px solid black',
                                  }}
                                >
                                  {formatTotalHours(
                                    shiftData.availableShifts.hoursMap?.[
                                      getMUIHourIndex(hour, currentLocation.timeZone, hours)
                                    ] +
                                      shiftData.requestableShifts.hoursMap?.[
                                        getMUIHourIndex(hour, currentLocation.timeZone, hours)
                                      ],
                                  )}
                                </Grid>
                                <Grid item>
                                  {formatTotalHours(
                                    shiftData.assignedShifts.hoursMap?.[
                                      getMUIHourIndex(hour, currentLocation.timeZone, hours)
                                    ],
                                  )}
                                </Grid>
                              </Grid>
                            </DailyFooterCell>
                          ))}
                        {calendarView === calendarViews.weekly.name() &&
                          days.map((day) => (
                            <WeeklyFooterCell
                              key={`total-${day}`}
                              style={{
                                color: colorPalette.black,
                              }}
                            >
                              <Grid container direction='column' alignItems='center'>
                                <Grid
                                  item
                                  style={{
                                    borderBottom: '1px solid black',
                                  }}
                                >
                                  {formatTotalHours(
                                    shiftData.availableShifts.hoursMap[day.weekday - 1] +
                                      shiftData.requestableShifts.hoursMap[day.weekday - 1],
                                  )}
                                </Grid>
                                <Grid item>
                                  {formatTotalHours(
                                    shiftData.assignedShifts.hoursMap[day.weekday - 1],
                                  )}
                                </Grid>
                              </Grid>
                            </WeeklyFooterCell>
                          ))}
                      </TableRow>
                    </TableFooter>
                  )}
                </StyledTable>
              </StyledTableContainer>
            )}
            {(scheduleViewFilter === 'all' || scheduleViewFilter === 'request') &&
              currentLocation.shiftRequestEnabled && (
                <StyledTableContainer
                  style={{
                    height: scheduleViewFilter === 'all' ? '20%' : '91%',
                  }}
                >
                  <StyledTable size='small'>
                    <TableHead>
                      <TableRow>
                        <TitleCell
                          cellType={cellTypes.header}
                          mode={tableCellViewModes.available}
                          rowHasData
                          index={0}
                        />
                        {calendarView === calendarViews.daily.name() &&
                          hours.map((hour) => (
                            <DailyHeaderCell key={`sort-${hour}`}>
                              {formatHour(hour, currentLocation.timeZone)}
                            </DailyHeaderCell>
                          ))}
                        {calendarView === calendarViews.weekly.name() &&
                          days.map((day) => (
                            <WeeklyHeaderCell key={`sort-${day}`}>
                              {formatSingleDayNoYear(day, currentLocation.timeZone)}
                            </WeeklyHeaderCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* NOTE: if no shifts, render first data cell and empty cells */}
                      {shiftData.requestableShifts.shifts?.length === 0 && (
                        <TableRow key={`${JSON.stringify(shiftData.requestableShifts)}`}>
                          <TitleCell
                            cellType={cellTypes.data}
                            mode={tableCellViewModes.available}
                            rowHasData={false}
                            index={0}
                            hoursSum={shiftData.requestableShifts.hoursSum}
                            length={shiftData.requestableShifts.shifts.length}
                            totalTimeInMinutes={shiftData.requestableShifts.hoursSum}
                            mustRequest
                          />
                          {mapShiftsToRow(null, [], 0, true, true)}
                        </TableRow>
                      )}
                      {shiftData.requestableShifts.shifts.map((shiftRow, index) => (
                        <TableRow key={`${JSON.stringify(shiftRow)}-${index}`}>
                          <TitleCell
                            cellType={cellTypes.data}
                            mode={tableCellViewModes.available}
                            rowHasData
                            index={index}
                            hoursSum={shiftData.requestableShifts.hoursSum}
                            length={shiftData.requestableShifts.shifts.length}
                            totalTimeInMinutes={shiftData.requestableShifts.hoursSum}
                            mustRequest
                          />
                          {mapShiftsToRow(null, shiftRow, index, true, true)}
                        </TableRow>
                      ))}
                    </TableBody>
                    {scheduleViewFilter === 'request' && (
                      <TableFooter>
                        <TableRow>
                          <TitleCell
                            cellType={cellTypes.footer}
                            mode={tableCellViewModes.available}
                            rowHasData
                            index={0}
                            availableHoursSum={shiftData.requestableShifts.hoursSum}
                            assignedHoursSum={shiftData.requestableShifts.hoursSum}
                            mustRequest
                          />
                          {calendarView === calendarViews.daily.name() &&
                            hours.map((hour) => (
                              <DailyFooterCell
                                key={`total-${hour}`}
                                style={{
                                  color: colorPalette.black,
                                }}
                              >
                                <Grid container direction='column' alignItems='center'>
                                  <Grid
                                    item
                                    style={{
                                      borderBottom: '1px solid black',
                                    }}
                                  >
                                    {formatTotalHours(
                                      shiftData.availableShifts.hoursMap?.[
                                        getMUIHourIndex(hour, currentLocation.timeZone, hours)
                                      ] +
                                        shiftData.requestableShifts.hoursMap?.[
                                          getMUIHourIndex(hour, currentLocation.timeZone, hours)
                                        ],
                                    )}
                                  </Grid>
                                  <Grid item>
                                    {formatTotalHours(
                                      shiftData.assignedShifts.hoursMap?.[
                                        getMUIHourIndex(hour, currentLocation.timeZone, hours)
                                      ],
                                    )}
                                  </Grid>
                                </Grid>
                              </DailyFooterCell>
                            ))}
                          {calendarView === calendarViews.weekly.name() &&
                            days.map((day) => (
                              <WeeklyFooterCell
                                key={`total-${day}`}
                                style={{
                                  color: colorPalette.black,
                                }}
                              >
                                <Grid container direction='column' alignItems='center'>
                                  <Grid
                                    item
                                    style={{
                                      borderBottom: '1px solid black',
                                    }}
                                  >
                                    {formatTotalHours(
                                      shiftData.availableShifts.hoursMap[day.weekday - 1] +
                                        shiftData.requestableShifts.hoursMap[day.weekday - 1],
                                    )}
                                  </Grid>
                                  <Grid item>
                                    {formatTotalHours(
                                      shiftData.assignedShifts.hoursMap[day.weekday - 1],
                                    )}
                                  </Grid>
                                </Grid>
                              </WeeklyFooterCell>
                            ))}
                        </TableRow>
                      </TableFooter>
                    )}
                  </StyledTable>
                </StyledTableContainer>
              )}
            {(scheduleViewFilter === 'all' || scheduleViewFilter === 'assigned') && (
              <StyledTableContainer
                style={{
                  height: scheduleViewFilter === 'all' ? scheduledShiftTableHeight : '91%',
                }}
              >
                <StyledTable size='small'>
                  <TableHead>
                    <TableRow>
                      <TitleCell
                        cellType={cellTypes.header}
                        mode={tableCellViewModes.assigned}
                        rowHasData
                        index={0}
                        showSort
                      />
                      {calendarView === calendarViews.daily.name() &&
                        hours.map((hour) => (
                          <DailyHeaderCell key={`sort-${hour}`}>
                            {formatHour(hour, currentLocation.timeZone)}
                          </DailyHeaderCell>
                        ))}
                      {calendarView === calendarViews.weekly.name() &&
                        days.map((day) => (
                          <WeeklyHeaderCell key={`sort-${day}`}>
                            {formatSingleDayNoYear(day, currentLocation.timeZone)}
                          </WeeklyHeaderCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* NOTE: overlapped shift display supported for drivers */}
                    {/* CONT: in case of unforseen situation, so manager can unassign */}
                    {/* TODO: add sorting support */}
                    {(sortBy === sortByOptions.SHIFTLIST
                      ? peopleSortedByShiftsList
                      : peopleSortedByAlphabet
                    )
                      .slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)
                      .map((person, personIndex) => {
                        // NOTE: if no shifts, render first data cell and empty cells */
                        if (!shiftData.assignedShifts[person.id]?.shifts?.length) {
                          return (
                            <TableRow
                              {...({} as any)}
                              key={`${JSON.stringify(person.id)}-${personIndex}`}
                              height={sharedMeasurements.innerCellHeight}
                            >
                              {/* NOTE: render initial driver cell every row */}
                              {/* NOTE: but render borders and data as needed */}
                              <TitleCell
                                cellType={cellTypes.data}
                                mode={tableCellViewModes.assigned}
                                rowHasData={false}
                                index={0}
                                hoursSum={shiftData.assignedShifts[person.id]?.totalTimeInMinutes}
                                length={1}
                                totalTimeInMinutes={0}
                                firstName={person.firstName}
                                lastName={person.lastName}
                              />
                              {mapShiftsToRow(person, [], 0, false)}
                            </TableRow>
                          );
                        }

                        return shiftData.assignedShifts[person.id].shifts.map(
                          (shiftRow, shiftRowIndex) => (
                            <TableRow
                              {...({} as any)}
                              key={`${JSON.stringify(person)}-${shiftRowIndex}`}
                              height={sharedMeasurements.innerCellHeight}
                            >
                              {/* NOTE: render initial driver cell every row */}
                              {/* NOTE: but render borders and data as needed */}
                              <TitleCell
                                cellType={cellTypes.data}
                                mode={tableCellViewModes.assigned}
                                rowHasData={listContainsShift(shiftRow)}
                                index={shiftRowIndex}
                                hoursSum={shiftData.availableShifts.hoursSum}
                                length={shiftData.assignedShifts[person.id].shifts.length}
                                totalTimeInMinutes={
                                  shiftData.assignedShifts[person.id]?.totalTimeInMinutes || 0
                                }
                                firstName={person.firstName}
                                lastName={person.lastName}
                              />
                              {mapShiftsToRow(person, shiftRow, shiftRowIndex, false)}
                            </TableRow>
                          ),
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TitleCell
                        cellType={cellTypes.footer}
                        mode={tableCellViewModes.assigned}
                        rowHasData
                        index={0}
                        availableHoursSum={
                          shiftData.availableShifts.hoursSum + shiftData.requestableShifts.hoursSum
                        }
                        assignedHoursSum={shiftData.assignedShifts.hoursSum}
                      />
                      {calendarView === calendarViews.daily.name() &&
                        hours.map((hour) => (
                          <DailyFooterCell
                            key={`total-${hour}`}
                            style={{
                              color: colorPalette.black,
                            }}
                          >
                            <Grid container direction='column' alignItems='center'>
                              <Grid
                                item
                                style={{
                                  borderBottom: '1px solid black',
                                }}
                              >
                                {formatTotalHours(
                                  shiftData.availableShifts.hoursMap?.[
                                    getMUIHourIndex(hour, currentLocation.timeZone, hours)
                                  ] +
                                    shiftData.requestableShifts.hoursMap?.[
                                      getMUIHourIndex(hour, currentLocation.timeZone, hours)
                                    ],
                                )}
                              </Grid>
                              <Grid item>
                                {formatTotalHours(
                                  shiftData.assignedShifts.hoursMap?.[
                                    getMUIHourIndex(hour, currentLocation.timeZone, hours)
                                  ],
                                )}
                              </Grid>
                            </Grid>
                          </DailyFooterCell>
                        ))}
                      {calendarView === calendarViews.weekly.name() &&
                        days.map((day) => (
                          <WeeklyFooterCell
                            key={`total-${day}`}
                            style={{
                              color: colorPalette.black,
                            }}
                          >
                            <Grid container direction='column' alignItems='center'>
                              <Grid
                                item
                                style={{
                                  borderBottom: '1px solid black',
                                }}
                              >
                                {formatTotalHours(
                                  shiftData.availableShifts.hoursMap[day.weekday - 1] +
                                    shiftData.requestableShifts.hoursMap[day.weekday - 1],
                                )}
                              </Grid>
                              <Grid item>
                                {formatTotalHours(
                                  shiftData.assignedShifts.hoursMap[day.weekday - 1],
                                )}
                              </Grid>
                            </Grid>
                          </WeeklyFooterCell>
                        ))}
                    </TableRow>
                  </TableFooter>
                </StyledTable>
              </StyledTableContainer>
            )}
            <TableContainer
              style={{
                height: '9%',
              }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <StyledTablePagination
                      rowsPerPageOptions={paginationOptions}
                      count={peopleSortedByShiftsList.length}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage={`${t(`${i18Prefix}.rows_per_page`)}:`}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} ${t(`${i18Prefix}.of`)} ${count}`
                      }
                      page={pageNumber}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      backIconButtonProps={
                        {
                          'data-testid': paginationBackButtonTestId,
                        } as any
                      }
                      nextIconButtonProps={
                        {
                          'data-testid': paginationNextButtonTestId,
                        } as any
                      }
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </FillVerticalBox>
        )}
      </Box>
    </FillVerticalBox>
  );
};

export { schedulerTestId };
export default Scheduler;
