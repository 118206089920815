import api from './api';

/**
 * Function to notify delivery partners
 * @param options.locationIds an array of locationIds to notify
 * @param options.userIds optional array of userIds to notify, overrides locationIds
 * @param options.subject notification subject field
 * @param options.message notification message field
 *  Logging happens upstream
 *  Error handling happens downstream
 */
const notify = async ({ locationIds, userIds, subject, message }) => {
  if (!Array.isArray(locationIds) || locationIds.length < 1)
    throw new Error('locationIds parameter not set correctly');

  if (userIds && (!Array.isArray(userIds) || userIds.length < 1))
    throw new Error('userIds parameter not set correctly');

  if (!subject) throw new Error('subject parameter not set correctly');
  if (!message) throw new Error('message parameter not set correctly');

  const response = await api.post('/v2/manager/notify', {
    locationIds,
    userIds,
    subject,
    message,
  });

  return response.data;
};

export { notify };
