import { version } from '../../package.json';

const Healthz = () => (
  <code id='healthz'>
    {JSON.stringify({
      status: 'healthy',
      version,
    })}
  </code>
);

export default Healthz;
