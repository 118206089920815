import { Interval, DateTime } from 'luxon';
import config from '../config';
import { getDaysForWeekMap, getDifferenceAsMinutes, getHoursForDayMap } from '../utilities/Dates';
import { calendarViews, deliveryMethods } from '../utilities/Variables';

const hasOverlap = (shiftA, shiftB) => {
  if (shiftA.startTime < shiftB.endTime && shiftA.endTime > shiftB.startTime) {
    return true;
  }

  return false;
};

/**
 * Function to calculate and add to weekly hours
 * @param {DateTime} startTime the start time of the shift
 * @param {DateTime} endTime the end time of the shift
 * @param {object} shiftDataObject the current shiftDataObject
 * @param {string} timeZone the current location time zone
 * Function modifies the shiftDataObject by updating
 * and adding to the map and sum for weekly hours
 */
const calcWeeklyHours = (
  startTime: DateTime,
  endTime: DateTime,
  shiftDataObject,
  timeZone: string,
  hoursInWeekMap: Map<number, Array<DateTime>>,
) => {
  const timezoneStartTime = startTime.setZone(timeZone);
  const timezoneEndTime = endTime.setZone(timeZone);

  // get shift interval, duration of the interval, and array of intervals for if it spans multiple days
  const shiftInterval = Interval.fromDateTimes(timezoneStartTime, timezoneEndTime);
  const shiftDuration = shiftInterval.toDuration('minute').minutes;

  // this is a counter used to see where we are in the week for the map, get the starting weekday initially
  let currentDayIndex = Object.values(hoursInWeekMap).findIndex(
    (hourList) => timezoneStartTime <= hourList.at(-1).endOf('hour'),
  );

  const endOfDay = hoursInWeekMap[currentDayIndex].at(-1).plus({ hours: 1 }).startOf('hour');
  const splitStartDay = shiftInterval.splitAt(endOfDay);

  // iterate through splitStartDay (it may be only 1 item)
  splitStartDay.forEach((currentInterval) => {
    // if the currentDayForMap is still within this week (below 7)
    if (currentDayIndex > -1 && currentDayIndex < 7) {
      // get the duration for the current interval
      const currentDayDuration = currentInterval.toDuration('minute').minutes;
      // if the shiftDuration is less than currentDayDuration, we know the shift doesnt span multiple days
      // so we just use shiftDuration, and the loop will end
      // if the shiftDuration is greater than currentDayDuration, we know the shift spans multile days
      // so for the current index, use this currentDayDuration
      const durationToUse =
        shiftDuration <= currentDayDuration ? shiftDuration : currentDayDuration;
      shiftDataObject.hoursMap[currentDayIndex] += durationToUse;
      shiftDataObject.hoursSum += durationToUse;
      currentDayIndex += 1;
    }
  });

  return shiftDataObject;
};

const getMUIHourIndex = (time: DateTime, timeZone: string, hoursInDay: Array<DateTime>) =>
  hoursInDay.findIndex((hour) => hour.equals(time.setZone(timeZone).startOf('hour')));

/**
 * Function to calculate and add to daily hours
 * @param {DateTime} startTime the start time of the shift
 * @param {DateTime} endTime the end time of the shift
 * @param {object} shiftDataObject the current shiftDataObject
 * @param {string} timeZone the current location time zone
 * @param {DateTime} date the date being viewed by the user
 * Function modifies the shiftDataObject by updating
 * and adding to the map and sum for daily hours
 */
const calcDailyHours = (
  startTime: DateTime,
  endTime: DateTime,
  shiftDataObject,
  timeZone: string,
  hoursInDay: Array<DateTime>,
) => {
  const timezoneStartTime = startTime.setZone(timeZone);
  const timezoneEndTime = endTime.setZone(timeZone);

  const nextDayStart = hoursInDay.at(-1).plus({ hours: 1 }).startOf('hour');
  if (timezoneStartTime < nextDayStart) {
    const muiStartHourIndex = getMUIHourIndex(timezoneStartTime, timeZone, hoursInDay);
    const muiEndHourIndex = getMUIHourIndex(timezoneEndTime, timeZone, hoursInDay);

    const startHourDuration = 60 - timezoneStartTime.minute;
    const endHourDuration = timezoneEndTime.minute;

    const numHoursInDay = hoursInDay.length;

    for (let i = muiStartHourIndex; i < numHoursInDay; i++) {
      if (i >= 0) {
        if (i === muiEndHourIndex) {
          shiftDataObject.hoursMap[i] += endHourDuration;
          shiftDataObject.hoursSum += endHourDuration;
          break;
        }
        if (i === muiStartHourIndex) {
          shiftDataObject.hoursMap[i] += startHourDuration;
          shiftDataObject.hoursSum += startHourDuration;
        } else {
          shiftDataObject.hoursMap[i] += 60;
          shiftDataObject.hoursSum += 60;
        }
      }
    }
  }

  return shiftDataObject;
};

// NOTE: 2d array in reversed style of display (rows are columns)
// EXAMPLE: [[{shift object}, {shift object}, ...], ...]
const addToDailyShifts = (
  shift,
  shiftDataObject,
  hoursMap,
  hoursSum: number,
  timeZone: string,
  hoursInDay: Array<DateTime>,
) => {
  let shiftData = shiftDataObject;

  // NOTE: add basic data if none exists
  if (!shiftData.shifts && shift.assignedPersonId === null) {
    shiftData = {
      shifts: [],
      totalTimeInMinutes: 0,
      hoursMap,
      hoursSum,
    };
  }

  shiftData = calcDailyHours(
    shift.displayStartTime,
    shift.endTime,
    shiftData,
    timeZone,
    hoursInDay,
  );

  const timezoneStartTime = shift.displayStartTime.setZone(timeZone);
  const nextDayStart = hoursInDay.at(-1).plus({ hours: 1 }).startOf('hour');
  if (timezoneStartTime < nextDayStart) {
    if (shift.assignedPersonId !== null) {
      shiftData = shiftDataObject[shift.assignedPersonId];
    }

    if (shiftData.shifts.length === 0) {
      shiftData.shifts.push([
        {
          ids: [shift.id],
          startTime: shift.startTime,
          displayStartTime: shift.displayStartTime,
          endTime: shift.endTime,
          assignedPersonId: shift.assignedPersonId,
          deliveryMethodId: shift.deliveryMethodId,
          isPublished: shift.isPublished,
          mustRequest: shift.mustRequest,
        },
      ]);
      shiftData.totalTimeInMinutes += getDifferenceAsMinutes(shift.endTime, shift.startTime);
    } else {
      let placed = false;
      let overlapped = false;
      // NOTE: 2D mapping for overlaps being moved to next row
      for (let i = 0; i < shiftData.shifts.length; i++) {
        overlapped = false;
        for (let j = 0; j < shiftData.shifts[i].length; j++) {
          // NOTE: if shifts are the same, add id to list and exit loops
          if (
            shift.startTime.equals(shiftData.shifts[i][j].startTime) &&
            shift.endTime.equals(shiftData.shifts[i][j].endTime) &&
            shift.assignedPersonId === shiftData.shifts[i][j].assignedPersonId &&
            shift.deliveryMethodId === shiftData.shifts[i][j].deliveryMethodId &&
            shift.isPublished === shiftData.shifts[i][j].isPublished &&
            shift.mustRequest === shiftData.shifts[i][j].mustRequest
          ) {
            // NOTE: double check for copy ids
            if (shift.id in shiftData.shifts[i][j].ids) {
              placed = true;
            } else {
              shiftData.shifts[i][j].ids.push(shift.id);
              placed = true;
              shiftData.totalTimeInMinutes += getDifferenceAsMinutes(
                shift.endTime,
                shift.startTime,
              );
            }
          } else if (hasOverlap(shift, shiftData.shifts[i][j])) {
            // NOTE: if shifts overlap, mark it and exit loops
            overlapped = true;
            break;
          } else if (j === shiftData.shifts[i].length - 1) {
            // NOTE: if no overlaps for all shifts in row, add to row and exit loops
            shiftData.shifts[i].push({
              ids: [shift.id],
              startTime: shift.startTime,
              displayStartTime: shift.displayStartTime,
              endTime: shift.endTime,
              assignedPersonId: shift.assignedPersonId,
              deliveryMethodId: shift.deliveryMethodId,
              isPublished: shift.isPublished,
              mustRequest: shift.mustRequest,
            });
            shiftData.totalTimeInMinutes += getDifferenceAsMinutes(shift.endTime, shift.startTime);
            placed = true;
          }

          if (placed) {
            break;
          }
        }

        if (placed) {
          break;
        }
      }

      if (overlapped && !placed) {
        shiftData.shifts.push([
          {
            ids: [shift.id],
            startTime: shift.startTime,
            displayStartTime: shift.displayStartTime,
            endTime: shift.endTime,
            assignedPersonId: shift.assignedPersonId,
            deliveryMethodId: shift.deliveryMethodId,
            isPublished: shift.isPublished,
            mustRequest: shift.mustRequest,
          },
        ]);
        shiftData.totalTimeInMinutes += getDifferenceAsMinutes(shift.endTime, shift.startTime);
      }
    }
  }

  return shiftData;
};

const deriveIndexFromDate = (shiftKey) => {
  const derivedHour = shiftKey.hour - 5;

  if (derivedHour < 0) {
    return shiftKey.startOf('day').minus({ days: 1 }).set({ hour: 5 });
  }

  return shiftKey.startOf('day').set({ hour: 5 });
};

// NOTE: object containing 2d array in style of display
// EXAMPLE:
// { shifts: [[{shift object}, {shift object}, ...], ...]
//   totalTimeInMinutes: ##
// }
const addToWeeklyShifts = (
  shift,
  shiftDataObject,
  hoursMap,
  hoursSum,
  dateMap,
  timeZone,
  hoursInWeekMap,
) => {
  let shiftData = shiftDataObject;

  if (!shiftData.shifts && shift.assignedPersonId === null) {
    shiftData = {
      shifts: [Array(7).fill(null)],
      totalTimeInMinutes: 0,
      hoursMap,
      hoursSum,
    };
  }

  const shiftKey = shift.startTime.setZone(timeZone);
  const shiftPlacementIndex = dateMap[deriveIndexFromDate(shiftKey)];
  const hoursStartTime = shift.startTime.setZone(timeZone);
  const hoursEndTime = shift.endTime.setZone(timeZone);

  // NOTE: if start of day is in the week selected, continue
  if (shiftPlacementIndex !== undefined && shiftPlacementIndex !== null) {
    shiftData = calcWeeklyHours(hoursStartTime, hoursEndTime, shiftData, timeZone, hoursInWeekMap);

    if (shift.assignedPersonId !== null) {
      shiftData = shiftDataObject[shift.assignedPersonId];
    }

    let placed = false;

    // NOTE: single loop to find which column in row shift assigned to
    for (let i = 0; i < shiftData.shifts.length; i++) {
      if (shiftData.shifts[i][shiftPlacementIndex] == null) {
        shiftData.shifts[i][shiftPlacementIndex] = {
          ids: [shift.id],
          startTime: shift.startTime,
          endTime: shift.endTime,
          assignedPersonId: shift.assignedPersonId,
          deliveryMethodId: shift.deliveryMethodId,
          isPublished: shift.isPublished,
          mustRequest: shift.mustRequest,
        };
        shiftData.totalTimeInMinutes += getDifferenceAsMinutes(shift.endTime, shift.startTime);

        placed = true;
      } else if (
        // NOTE: if shift is same
        shift.startTime.equals(shiftData.shifts[i][shiftPlacementIndex].startTime) &&
        shift.endTime.equals(shiftData.shifts[i][shiftPlacementIndex].endTime) &&
        shift.deliveryMethodId === shiftData.shifts[i][shiftPlacementIndex].deliveryMethodId &&
        shift.isPublished === shiftData.shifts[i][shiftPlacementIndex].isPublished &&
        shift.mustRequest === shiftData.shifts[i][shiftPlacementIndex].mustRequest
      ) {
        // NOTE: double check for copy ids
        if (shift.id in shiftData.shifts[i][shiftPlacementIndex].ids) {
          placed = true;
        } else {
          shiftData.shifts[i][shiftPlacementIndex].ids.push(shift.id);

          shiftData.totalTimeInMinutes += getDifferenceAsMinutes(shift.endTime, shift.startTime);
          placed = true;
        }
      }

      if (placed) {
        break;
      }
    }

    if (!placed) {
      const bottomRow = Array(7).fill(null);
      bottomRow[shiftPlacementIndex] = {
        ids: [shift.id],
        startTime: shift.startTime,
        endTime: shift.endTime,
        assignedPersonId: shift.assignedPersonId,
        deliveryMethodId: shift.deliveryMethodId,
        isPublished: shift.isPublished,
        mustRequest: shift.mustRequest,
      };
      shiftData.totalTimeInMinutes += getDifferenceAsMinutes(shift.endTime, shift.startTime);

      shiftData.shifts.push(bottomRow);
    }
  }

  return shiftData;
};

// first looks at startTime, then endTime
const compareShifts = (shiftA, shiftB) => {
  const startA = DateTime.fromISO(shiftA?.startTime);
  const startB = DateTime.fromISO(shiftB?.startTime);
  const endA = DateTime.fromISO(shiftA?.endTime);
  const endB = DateTime.fromISO(shiftB?.endTime);
  if (startA < startB || (startA === startB && endA > endB)) return -1;
  if (startA > startB || (startA === startB && endA < endB)) return 1;
  return 0;
};

const addShiftsFromServer = (options) => {
  const {
    filteredShiftData,
    calendarView,
    date,
    timeZone,
    dateMap = null,
    hoursInDay = null,
    hoursInWeek = null,
  } = options;

  // ensure list of shifts is always added chronologically
  const sortedShifts = filteredShiftData.sort(compareShifts);

  const formattedShiftData: any = {
    availableShifts: {
      hoursSum: 0,
      hoursMap: [],
      totalTimeInMinutes: 0,
      shifts: [],
    },
    requestableShifts: {
      hoursSum: 0,
      hoursMap: [],
      totalTimeInMinutes: 0,
      shifts: [],
    },
    assignedShifts: {
      hoursSum: 0,
      hoursMap: [],
      deliveryPartners: [],
    },
  };

  const deliveryPartnerIds = new Set();
  const day = date.setZone(timeZone).startOf('day');

  if (calendarView === calendarViews.daily.name()) {
    const numHoursInDay = hoursInDay.length;

    formattedShiftData.availableShifts.hoursMap = getHoursForDayMap(numHoursInDay);
    formattedShiftData.requestableShifts.hoursMap = getHoursForDayMap(numHoursInDay);
    formattedShiftData.assignedShifts.hoursMap = getHoursForDayMap(numHoursInDay);
    const displayStartDay = day.set({ hours: 5 });

    sortedShifts.forEach((shift) => {
      const startTime = DateTime.fromISO(shift.startTime).setZone(timeZone);
      const endTime = DateTime.fromISO(shift.endTime).setZone(timeZone);

      const shiftOverlapsDay = startTime < displayStartDay && endTime > displayStartDay;

      shift = {
        ...shift,
        startTime,
        displayStartTime: shiftOverlapsDay ? displayStartDay : startTime,
        endTime,
      };

      if (shift.assignedPersonId == null) {
        if (shift.mustRequest === true) {
          formattedShiftData.requestableShifts = addToDailyShifts(
            shift,
            formattedShiftData.requestableShifts,
            formattedShiftData.requestableShifts.hoursMap,
            formattedShiftData.requestableShifts.hoursSum,
            timeZone,
            hoursInDay,
          );
        } else {
          formattedShiftData.availableShifts = addToDailyShifts(
            shift,
            formattedShiftData.availableShifts,
            formattedShiftData.availableShifts.hoursMap,
            formattedShiftData.availableShifts.hoursSum,
            timeZone,
            hoursInDay,
          );
        }
      } else {
        if (!deliveryPartnerIds.has(shift.assignedPersonId)) {
          deliveryPartnerIds.add(shift.assignedPersonId);
        }

        if (
          Object.keys(formattedShiftData.assignedShifts).length === 0 ||
          !Object.keys(formattedShiftData.assignedShifts).includes(
            shift.assignedPersonId.toString(),
          )
        ) {
          formattedShiftData.assignedShifts[shift.assignedPersonId] = {
            shifts: [],
            totalTimeInMinutes: 0,
          };
        }

        addToDailyShifts(
          shift,
          formattedShiftData.assignedShifts,
          formattedShiftData.assignedShifts.hoursMap,
          formattedShiftData.assignedShifts.hoursSum,
          timeZone,
          hoursInDay,
        );
      }
    });
  } else {
    formattedShiftData.availableShifts.hoursMap = getDaysForWeekMap();
    formattedShiftData.requestableShifts.hoursMap = getDaysForWeekMap();
    formattedShiftData.assignedShifts.hoursMap = getDaysForWeekMap();

    sortedShifts.forEach((shift) => {
      const startTime = DateTime.fromISO(shift.startTime).toUTC().setZone(timeZone);
      const endTime = DateTime.fromISO(shift.endTime).toUTC().setZone(timeZone);

      shift = {
        ...shift,
        startTime,
        endTime,
      };

      if (shift.assignedPersonId == null) {
        if (shift.mustRequest === true) {
          formattedShiftData.requestableShifts = addToWeeklyShifts(
            shift,
            formattedShiftData.requestableShifts,
            formattedShiftData.requestableShifts.hoursMap,
            formattedShiftData.requestableShifts.hoursSum,
            dateMap,
            timeZone,
            hoursInWeek,
          );
        } else {
          formattedShiftData.availableShifts = addToWeeklyShifts(
            shift,
            formattedShiftData.availableShifts,
            formattedShiftData.availableShifts.hoursMap,
            formattedShiftData.availableShifts.hoursSum,
            dateMap,
            timeZone,
            hoursInWeek,
          );
        }
      } else {
        if (!deliveryPartnerIds.has(shift.assignedPersonId)) {
          deliveryPartnerIds.add(shift.assignedPersonId);
        }

        if (
          Object.keys(formattedShiftData.assignedShifts).length === 0 ||
          !Object.keys(formattedShiftData.assignedShifts).includes(
            shift.assignedPersonId.toString(),
          )
        ) {
          formattedShiftData.assignedShifts[shift.assignedPersonId] = {
            shifts: [Array(7).fill(null)],
            totalTimeInMinutes: 0,
          };
        }

        addToWeeklyShifts(
          shift,
          formattedShiftData.assignedShifts,
          formattedShiftData.assignedShifts.hoursMap,
          formattedShiftData.assignedShifts.hoursSum,
          dateMap,
          timeZone,
          hoursInWeek,
        );
      }
    });
  }

  // Above we used a set for the delivery partner ids, since it is a primitive type
  // This made sure we did not get any duplicate ids
  // Now that we have all the ids, we can create our array for the objects
  // Iterate through the set of delivery partner ids to create the array
  deliveryPartnerIds.forEach((id) => {
    formattedShiftData.assignedShifts.deliveryPartners.push({
      id,
      firstName: 'ID:',
      lastName: `${id}`,
    });
  });

  if (!formattedShiftData.availableShifts.shifts) {
    formattedShiftData.availableShifts.shifts = [];
    formattedShiftData.availableShifts.totalTimeInMinutes = 0;
    formattedShiftData.availableShifts.hoursSum = 0;
  }
  return formattedShiftData;
};

// filters out shifts from incomingShiftData based on delivery method
const filterShifts = (incomingShiftData, deliveryMethodFilter) => {
  const deliveryMethodList = Object.keys(deliveryMethodFilter)
    .filter((key) => deliveryMethodFilter[key])
    .filter((key) => config.DISPLAY_MOPED || key !== 'moped')
    .map((method) => deliveryMethods[method].id);
  return incomingShiftData.filter((shift) => deliveryMethodList.includes(shift.deliveryMethodId));
};

export {
  hasOverlap,
  addToDailyShifts,
  addToWeeklyShifts,
  addShiftsFromServer,
  compareShifts,
  filterShifts,
  getMUIHourIndex,
};
