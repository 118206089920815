import { atom, selector } from 'recoil';
import { countAvailableShifts, countUnpublishedShifts } from '../utilities/Functions';
import { pageState } from '../utilities/Variables';
import { allShiftsState } from './ShiftState';

export const drawerState = atom({
  key: 'drawerState',
  default: true,
});

export const primaryActionState = selector({
  key: 'primaryActionState',
  get: ({ get }) => {
    const shifts = get(allShiftsState);
    if (countUnpublishedShifts(shifts) > 0) return 'publish';
    if (countAvailableShifts(shifts) > 0) return 'unpublish';
    return 'notify';
  },
});

export const pageLoadingState: any = atom({
  key: 'pageLoadingState',
  default: pageState.loading,
});
