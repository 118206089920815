import i18n from 'i18next';
import { DateTime } from 'luxon';
import { i18Prefix } from '../i18Constants';
import '../i18n';
import { CurrentLocation } from './Interfaces';
import { calendarViews } from './Variables';

const numDaysInWeek = 7;

const getWeekFromDay = (dayInWeek: DateTime, timeZone: string) => {
  const days: DateTime[] = [];
  const day = dayInWeek.setZone(timeZone);
  const weekStart = day.startOf('week').set({ hour: 5 });

  for (let i = 0; i < numDaysInWeek; i++) {
    days.push(weekStart.plus({ days: i }));
  }

  return days;
};

const getHoursForDay = (date: DateTime, timeZone: string) => {
  const hours = [];
  const day = date.setZone(timeZone);
  const goPuffDayStart = day.startOf('day').set({ hour: 5 });
  const goPuffDayEnd = day.plus({ days: 1 }).set({ hour: 4 }).endOf('hour');

  // NOTE: DST hour changes come from luxon (I.E. 1AM, 1AM, 2AM or 1AM, 3AM, 4AM)
  let hour = goPuffDayStart;
  while (hour < goPuffDayEnd) {
    hours.push(hour);
    hour = hour.plus({ hours: 1 });
  }

  return hours;
};

const getHoursForDayMap = (hoursInDay: number) => {
  const hours = {};
  for (let i = 0; i < hoursInDay; i++) {
    hours[i] = 0;
  }
  return hours;
};

const getDaysForWeekMap = () => {
  const days = {};
  for (let i = 0; i < numDaysInWeek; i++) {
    days[i] = 0;
  }
  return days;
};

const getHoursListsForWeek = (day, timezone) => {
  const week = getWeekFromDay(day, timezone);

  const days = {};
  for (let i = 0; i < numDaysInWeek; i++) {
    days[i] = getHoursForDay(week[i], timezone);
  }
  return days;
};

const getDateMapForWeek = (date: DateTime, timeZone: string) =>
  getWeekFromDay(date, timeZone).reduce((result, item, index) => {
    result[item.toISO()] = index;
    return result;
  }, {});

const formatDateRange = (firstDay: DateTime, lastDay: DateTime) => {
  firstDay.setLocale(i18n.language);
  lastDay.setLocale(i18n.language);

  if (firstDay.year === lastDay.year && firstDay.month !== lastDay.month) {
    return `${firstDay.toFormat('MMM d')} - ${lastDay.toFormat('MMM d')}, ${firstDay.toFormat(
      'y',
    )}`;
  }

  if (firstDay.year !== lastDay.year) {
    return `${firstDay.toFormat('MMM d, y')} - ${lastDay.toFormat('MMM d, y')}`;
  }

  return `${firstDay.toFormat('MMMM d')} - ${lastDay.toFormat('d')}, ${firstDay.toFormat('y')}`;
};

const formatHourRange = (startTime: DateTime, endTime: DateTime, timeZone: string) => {
  const luxonStartTime = startTime.setZone(timeZone);
  const luxonEndTime = endTime.setZone(timeZone);

  return `${luxonStartTime
    .setLocale(i18n.language)
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(':00', '')} - ${luxonEndTime
    .setLocale(i18n.language)
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(':00', '')}`;
};

const formatSingleDay = (date: DateTime, timeZone: string) =>
  date.setZone(timeZone).setLocale(i18n.language).toLocaleString({
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

const formatSingleDayNoYear = (date: DateTime, timeZone: string) =>
  date
    .setZone(timeZone)
    .setLocale(i18n.language)
    .toLocaleString({ weekday: 'long', month: 'long', day: 'numeric' });

const formatHour = (date: DateTime, timeZone: string) =>
  date
    .setZone(timeZone)
    .setLocale(i18n.language)
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(' ', '');

const formatTime = (date) => date.toFormat('hh:mm a');

function formatDriverTotalHours(totalHours: number) {
  return `${totalHours.toFixed(totalHours % 10 == 0 ? 0 : 2)} ${i18n.t(`${i18Prefix}.hrs`)}`;
}

const formatTotalHours = (totalHours: number) => `${(totalHours / 60).toFixed(2)}`;

const getDifferenceAsHours = (endTime: DateTime, startTime: DateTime) =>
  endTime.diff(startTime).as('hours').toFixed(2);

const getDifferenceAsHoursVariableDisplay = (
  endTime: DateTime,
  startTime: DateTime,
  numSpaces: number,
) => {
  const diff = endTime.diff(startTime).as('minutes');
  const spacer = new Array(numSpaces).fill(' ').join('');

  // NOTE: using replace because .toFixed(0) and ternary expressions didn't work
  const hoursString = `${(diff / 60).toFixed(2)}${spacer}${i18n.t(`${i18Prefix}.hrs`)}`;
  return hoursString.replace('.00', '');
};

const getDifferenceAsHoursVariableDisplayFull = (
  endTime: DateTime,
  startTime: DateTime,
  numSpaces: number,
) => {
  if (!isValidDateTime(startTime) || !isValidDateTime(endTime)) {
    return 'N/A';
  }
  const diff = endTime.diff(startTime).as('minutes');
  const spacer = new Array(numSpaces).fill(' ').join('');

  // NOTE: using replace because .toFixed(0) and ternary expressions didn't work
  const hoursString = `${(diff / 60).toFixed(2)}${spacer}${i18n.t(`${i18Prefix}.hours`)}`;
  return hoursString.replace('.00', '');
};

const getDifferenceAsMinutes = (endTime: DateTime, startTime: DateTime) =>
  endTime.diff(startTime).as('minutes');

const isValidDateTime = (date) => DateTime.isDateTime(date) && date?.isValid;

const setTimeInDay = (time: DateTime, workday: DateTime) => {
  if (!isValidDateTime(time) || !isValidDateTime(workday)) return time;
  const { hour, minute } = time;
  const { year, month, day, zone } = workday;

  return DateTime.fromObject({
    year,
    month,
    day,
    hour,
    minute,
    second: 0,
    millisecond: 0,
  }).setZone(zone, { keepLocalTime: true });
};

//NOTE: datemap is currently getting local timezone.  this doesn't appear to update later
const generateDateMap = (date: DateTime, currentLocation: CurrentLocation) =>
  getWeekFromDay(date, currentLocation.timeZone).reduce((result, item, index) => {
    result[item.toISO()] = index;
    return result;
  }, {});

const getDateBoundsForShiftData = (
  calendarView: string,
  date: DateTime,
  days: DateTime[],
  currentLocation: CurrentLocation,
) => {
  let shiftStartTime: DateTime;
  let shiftEndTime: DateTime;
  let dateMap: Record<string, number>;
  // NOTE: date items are set to start of day by default
  if (calendarView === calendarViews.daily.name()) {
    shiftStartTime = date.setZone(currentLocation.timeZone).startOf('day').set({ hour: 5 });
    shiftEndTime = date
      .setZone(currentLocation.timeZone)
      .endOf('day')
      .plus({ days: 1 })
      .set({ hour: 4 });
  } else {
    shiftStartTime = days[0].setZone(currentLocation.timeZone);
    shiftEndTime = days[6]
      .setZone(currentLocation.timeZone)
      .endOf('day')
      .plus({ days: 1 })
      .set({ hour: 4 });
    dateMap = generateDateMap(date, currentLocation);
  }

  return { shiftStartTime, shiftEndTime, dateMap };
};

export {
  numDaysInWeek,
  getWeekFromDay,
  getHoursForDay,
  getHoursForDayMap,
  getDaysForWeekMap,
  getHoursListsForWeek,
  getDateMapForWeek,
  formatDateRange,
  formatHourRange,
  formatSingleDay,
  formatSingleDayNoYear,
  formatHour,
  formatTime,
  formatDriverTotalHours,
  formatTotalHours,
  getDifferenceAsHours,
  getDifferenceAsMinutes,
  getDifferenceAsHoursVariableDisplay,
  getDifferenceAsHoursVariableDisplayFull,
  isValidDateTime,
  setTimeInDay,
  getDateBoundsForShiftData,
  generateDateMap,
};
