import i18n from 'i18next';
import '../i18n';
import { i18Prefix } from '../i18Constants';
import api from './api';

const t = (key) => i18n.t(key);
/**
 * Function to send list of blocked delivery partners
 * @param shifts an array of shift objects to create
 *  Logging happens upstream
 *  Error handling happens downstream
 */
const postBlockList = async (blockListFile) => {
  // NOTE: keep in case function used from separate caller
  if (!blockListFile?.name || !blockListFile?.name.includes('.csv')) {
    throw new Error(t(`${i18Prefix}.invalid_file_type`));
  }
  const formData = new FormData();
  formData.append('users', blockListFile, blockListFile.name);

  const response = await api.post<any>('/v2/ops/blocklist', formData);
  return response.data;
};

export { postBlockList };
