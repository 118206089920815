import { compareShifts } from '../models/ShiftData';
import { CurrentLocation } from './Interfaces';

const countUnpublishedShifts = (shifts) => {
  if (!shifts || !Array.isArray(shifts)) return 0;
  const uniqueShifts = {};
  shifts.forEach((shift) => {
    if (!shift.isPublished) {
      const key = `${shift.startTime}-${shift.endTime}`;
      if (!uniqueShifts[key]) uniqueShifts[key] = shift;
    }
  });
  return Object.keys(uniqueShifts).length;
};

const countAvailableShifts = (shifts) => {
  if (!shifts || !Array.isArray(shifts)) return 0;
  return shifts.filter((shift) => shift.isPublished && shift.assignedPersonId == null).length;
};

// Looks at firstName first, then lastName
const comparePeopleAlphabetically = (personA, personB) => {
  const firstA = personA?.firstName;
  const firstB = personB?.firstName;
  const lastA = personA?.lastName;
  const lastB = personB?.lastName;
  if (firstA?.localeCompare(firstB) < 0) return -1;
  if (firstA?.localeCompare(firstB) === 0 && lastA?.localeCompare(lastB) < 0) return -1;
  if (firstA?.localeCompare(firstB) > 0) return 1;
  if (firstA?.localeCompare(firstB) === 0 && lastA?.localeCompare(lastB) > 0) return 1;
  return 0;
};

const sortPeopleByShifts = (peopleList, assignedShifts) =>
  peopleList.slice().sort((personA, personB) => {
    // if personB has no shifts scheduled, move them to the bottom
    if (assignedShifts[personA.id] && !assignedShifts[personB.id]) return -1;

    // if personA has no shifts scheduled, move them to the bottom
    if (!assignedShifts[personA.id] && assignedShifts[personB.id]) return 1;

    // if neither have shifts, do not change order
    if (!assignedShifts[personA.id] && !assignedShifts[personB.id]) return 0;

    let firstShiftA;
    let firstShiftB;

    // retrieve personA's first shift of the week
    assignedShifts[personA.id]?.shifts?.[0]?.forEach((day) => {
      if (!firstShiftA && day) firstShiftA = day;
    });

    // retrieve personB's first shift of the week
    assignedShifts[personB.id]?.shifts?.[0]?.forEach((day) => {
      if (!firstShiftB && day) firstShiftB = day;
    });

    // sort people based on which shift is earlier
    return compareShifts(firstShiftA, firstShiftB);
  });

const filterPeopleBySearch = (people, searchString, useEmail) => {
  const searchRegex = new RegExp(searchString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
  if (useEmail) {
    // optional parameter to match on email as well as first & last name
    return people.filter(
      (person) =>
        `${person.firstName} ${person.lastName}`.match(searchRegex) ||
        person.email?.match(searchRegex),
    );
  }
  return people.filter((person) => `${person.firstName} ${person.lastName}`.match(searchRegex));
};

const filterLocationsBySearch = (locations, searchString) => {
  const searchRegex = new RegExp(searchString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
  return locations.filter(
    (location) =>
      location.displayName?.match(searchRegex) ||
      location.name?.match(searchRegex) ||
      location.id?.toString().match(searchRegex),
  );
};

const hideUnscheduled = (peopleList, assignedShifts) =>
  peopleList.slice().filter((person) => {
    if (!assignedShifts[person.id]) return false;
    return true;
  });

// async/await friendly interface for setTimeout
const wait = (timeToDelay) => new Promise((resolve) => setTimeout(resolve, timeToDelay));

const sortPeople = (arr) =>
  arr.sort((a, b) =>
    `${a.lastName}, ${a.firstName}`.localeCompare(`${b.lastName}, ${b.firstName}`),
  );

const mergePeopleLists = (peopleWithName, peopleNoName) => {
  const tempPeopleList = peopleWithName.slice();
  peopleNoName.forEach((deliveryPartner) => {
    if (!peopleWithName.some((person) => person.id === deliveryPartner.id)) {
      tempPeopleList.push(deliveryPartner);
    }
  });
  return sortPeople(tempPeopleList);
};

const reorderPeoplesCurrentLocation = (people, currentLocation: CurrentLocation) => {
  people.forEach((person) => {
    const filteredLocations = person.locations.filter((obj) => obj.id !== currentLocation.id);
    person.locations = [
      {
        friendly_name: currentLocation.displayName || currentLocation.name,
      },
      ...filteredLocations,
    ];
  });
  return people;
};

export {
  countUnpublishedShifts,
  countAvailableShifts,
  comparePeopleAlphabetically,
  sortPeopleByShifts,
  wait,
  filterPeopleBySearch,
  filterLocationsBySearch,
  hideUnscheduled,
  sortPeople,
  mergePeopleLists,
  reorderPeoplesCurrentLocation,
};
