import { Close } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import i18n from 'i18next';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { modalState } from '../../atoms/ModalState';
import {
  calendarViewState,
  currentLocationState,
  dateState,
  userActionState,
} from '../../atoms/QueryState';
import { allShiftsState } from '../../atoms/ShiftState';
import { i18Prefix } from '../../i18Constants';
import '../../i18n';
import { notify } from '../../services/notification';
import { editShifts, getShifts } from '../../services/shifts';
import { getWeekFromDay, isValidDateTime, setTimeInDay } from '../../utilities/Dates';
import { countUnpublishedShifts } from '../../utilities/Functions';
import { popperProps } from '../../utilities/Props';
import {
  colorPalette,
  PrimaryActionButton,
  renderCustomDayPicker,
  SecondaryActionButton,
  StyledTextField,
} from '../../utilities/Styles';
import {
  calendarViews,
  modals,
  pageState,
  tempFeatureFlag,
  userActions,
} from '../../utilities/Variables';
import { closeModalTestId } from '../../utilities/TestIds';
import { pageLoadingState } from '../../atoms/PageState';
import { DateTime } from 'luxon';

const publishEndDateTestId = 'publishEndDate';
const publishTestId = 'publish';

const PublishScheduleModalBody = (props) => {
  const { t } = useTranslation();

  const setDisplayModal = useSetRecoilState(modalState);
  const setPageLoading = useSetRecoilState(pageLoadingState);
  const [userAction, setUserAction] = useRecoilState(userActionState);

  const displayModal = useRecoilValue(modalState);
  if (props.open) displayModal.open = true; // optional prop used when unit testing component

  const currentDate = useRecoilValue(dateState);
  const calendarView = useRecoilValue(calendarViewState);
  const currentLocation = useRecoilValue(currentLocationState);
  // scheduler shift state
  const allShifts = useRecoilValue(allShiftsState);

  const [startTimePickerOpen, setStartTimePickerOpen] = useState(false);
  const [endTimePickerOpen, setEndTimePickerOpen] = useState(false);

  useEffect(() => {
    return () => {
      setStartTimePickerOpen(false);
      setEndTimePickerOpen(false);
    };
  }, []);

  // if daily view, start of range = start of day
  // if weekly view, start of range = start of week
  const deriveStart = () => {
    if (calendarView === calendarViews.daily.name()) {
      const start = currentDate.startOf('day').set({ hour: 5 });
      return start;
    }
    const startDate = getWeekFromDay(currentDate, currentLocation.timeZone)[0];
    return startDate;
  };

  // if daily view, end of range = end of day
  // if weekly view, end of range = end of week
  const deriveEnd = () => {
    if (calendarView === calendarViews.daily.name()) {
      const end = currentDate.endOf('day').plus({ days: 1 }).set({ hour: 4 });
      return end;
    }

    const endDate = getWeekFromDay(currentDate, currentLocation.timeZone)[6].endOf('day');
    return endDate;
  };

  /* NOTE: This component has its own shift state because the user can
  alter the modal start and end time independently of the main scheduler body */
  const [shiftsToUpdate, setShiftsToUpdate] = useState(allShifts);
  const [apiCallLoading, setApiCallLoading] = useState(false);
  const [shiftUpdateLoading, setShiftUpdateLoading] = useState(false);
  const [apiError, setApiError] = useState(undefined);
  const [selectedStartDate, setSelectedStartDate] = useState(deriveStart());
  const [selectedEndDate, setSelectedEndDate] = useState(deriveEnd());
  let lastValidStartTime = deriveStart();
  let lastValidEndTime = deriveEnd();

  const getUnpublished = () => shiftsToUpdate.filter((shift) => !shift.isPublished);

  const getAvailable = () =>
    shiftsToUpdate.filter((shift) => shift.isPublished && shift.assignedPersonId == null);

  const handleStartDateChange = async (value: DateTime, keyboardInputValue: string) => {
    const startDate = resolveUpdatedTimeValue(value, keyboardInputValue, lastValidStartTime);

    if (isValidDateTime(startDate)) {
      lastValidStartTime = startDate;
    }

    const startDateSetValue = isValidDateTime(value) ? value.endOf('day') : value;
    setSelectedStartDate(startDateSetValue);
  };

  const handleEndDateChange = async (value: DateTime, keyboardInputValue: string) => {
    const endDate = resolveUpdatedTimeValue(value, keyboardInputValue, lastValidEndTime);

    if (isValidDateTime(endDate)) {
      lastValidEndTime = endDate;
    }

    const endDateSetValue = isValidDateTime(value) ? value.endOf('day') : value;
    setSelectedEndDate(endDateSetValue);
  };

  const resolveUpdatedTimeValue = (value: DateTime, keyboardInputValue: string, date: DateTime) => {
    const keyboardInputDate = setTimeInDay(
      DateTime.fromFormat(keyboardInputValue ?? '', getLocaleFormat()),
      date,
    );

    return isValidDateTime(keyboardInputDate) ? keyboardInputDate : value;
  };

  const getLocaleFormat = () => 'MM/dd/yyyy';

  const [messageState, setMessageState] = useState('');

  const handleMessageChange = (event) => {
    setMessageState(event.target.value);
  };

  const [notifyState, setNotifyState] = useState({
    notifyAllWithChanges: false,
    notifyAll: true,
  });

  const handleNotifyChange = (event) => {
    setNotifyState({
      ...notifyState,
      [event.target.name]: event.target.checked,
    });
  };

  // when the user updates the start or end date, refresh the set of shifts to update
  useEffect(() => {
    try {
      setShiftUpdateLoading(true);
      setApiError(undefined);
      // TODO: check if both setZones are needed.  remove one setZone if not
      const updateStartDate = isValidDateTime(selectedStartDate)
        ? selectedStartDate
            .setZone(currentLocation.timeZone, { keepLocalTime: true })
            .setZone('utc')
        : lastValidStartTime;
      const updateEndDate = isValidDateTime(selectedEndDate)
        ? selectedEndDate.setZone(currentLocation.timeZone, { keepLocalTime: true }).setZone('utc')
        : lastValidEndTime;
      const getShiftsObject = {
        startTime: updateStartDate.toISO(),
        endTime: updateEndDate.toISO(),
        locationId: currentLocation.id,
      };

      getShifts(getShiftsObject)
        .then((shifts) => {
          setShiftsToUpdate(shifts);
          setShiftUpdateLoading(false);
        })
        .catch((err) => handleGetShiftError(err));
    } catch (err) {
      handleGetShiftError(err);
    }
  }, [selectedStartDate, selectedEndDate]);

  const handleGetShiftError = (err: any) => {
    console.log(err);
    setShiftUpdateLoading(false);
    setShiftsToUpdate([]);
    setApiError(
      err.message || 'We are having trouble processing this request. Please contact support',
    );
  };

  const notifyAll = async ({ action }) => {
    try {
      let subject;
      let message;
      if (action === 'publish') {
        subject = 'New hours dropping';
        message =
          messageState ||
          'New hours are being added. Please open your app to take a slot. This is first come, first served';
      } else {
        // TODO: Unpublish messaging here
      }
      const response: any = await notify({
        locationIds: [currentLocation.id],
        subject,
        message,
      } as any);
      if (!response.success) {
        throw new Error(
          'Your changes were published but we were unable to notify drivers. Please contact support',
        );
      }
    } catch (err) {
      console.log(err);
      throw new Error(
        err.message ||
          'Your changes were published but we were unable to notify drivers. Please contact support',
      );
    }
  };

  const handlePublish = async () => {
    try {
      setApiCallLoading(true);
      setApiError(undefined);
      const updates = getUnpublished().map((shift) => ({
        id: shift.id,
        locationId: shift.locationId,
        isPublished: true,
        publishedTime: DateTime.now().toUTC().toISO(),
      }));
      const response = await editShifts(updates);

      // if the first entry failed assume others did as well and display error message
      if (!response[0] || response[0].success === false) {
        setApiCallLoading(false);
        setApiError(
          response[0]?.message ||
            'We are having trouble processing this request. Please contact support',
        );
      } else {
        if (notifyState.notifyAll) {
          await notifyAll({ action: 'publish' });
        }
        setPageLoading(pageState.loading);
        setUserAction({
          userAction: userActions.publish,
          actionCount: userAction.actionCount + 1,
        });
        handleClose();
      }
    } catch (err) {
      setApiCallLoading(false);
      console.log(err);
      setApiError(
        err.message || 'We are having trouble processing this request. Please contact support',
      );
    }
  };

  const handleUnpublish = async () => {
    try {
      setApiCallLoading(true);
      setApiError(undefined);
      const updates = getAvailable().map((shift) => ({
        id: shift.id,
        locationId: shift.locationId,
        isPublished: false,
        publishedTime: null,
      }));
      const response = await editShifts(updates);

      // if the first entry failed assume others did as well and display error message
      if (!response[0] || response[0].success === false) {
        setApiCallLoading(false);
        setApiError(
          response[0]?.message ||
            'We are having trouble processing this request. Please contact support',
        );
      } else {
        // if first entry succeeded, assume others succeeded as well and close modal
        setPageLoading(pageState.loading);
        setUserAction({
          userAction: userActions.unpublish,
          actionCount: userAction.actionCount + 1,
        });
        handleClose();
      }
    } catch (err) {
      setApiCallLoading(false);
      console.log(err);
      setApiError(
        err.message || 'We are having trouble processing this request. Please contact support',
      );
    }
  };

  const handleClose = () => {
    setDisplayModal({ open: false, modal: modals.publishModal } as any);
  };

  return (
    <div>
      <Dialog
        open={displayModal.open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='xs'
      >
        <DialogTitle id='form-dialog-title' style={{ backgroundColor: colorPalette.gray2 }}>
          {t(`${i18Prefix}.publish_schedule`)}
          <Button
            onClick={handleClose}
            data-testid={closeModalTestId}
            style={{
              float: 'right',
              padding: '2px',
              margin: '0px',
              color: 'rgb(0, 0, 0, 0.87)',
            }}
          >
            <Close
              style={{
                fontSize: '26px',
              }}
            />
          </Button>
        </DialogTitle>
        <DialogContent
          style={{ justifyContent: 'space-between', paddingTop: '8px', paddingBottom: '8px' }}
        >
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
            <div style={{ paddingBottom: 15 }}>
              <DesktopDatePicker
                disableMaskedInput={false}
                open={startTimePickerOpen}
                onOpen={() => setStartTimePickerOpen(true)}
                onClose={() => setStartTimePickerOpen(false)}
                inputFormat={getLocaleFormat()}
                value={selectedStartDate}
                onChange={handleStartDateChange}
                renderDay={renderCustomDayPicker}
                renderInput={(params) => (
                  <StyledTextField style={{ width: 176, marginTop: 15 }} {...params} />
                )}
                label={t(`${i18Prefix}.start_date`)}
                PopperProps={popperProps}
              />
              <DesktopDatePicker
                disableMaskedInput={false}
                open={endTimePickerOpen}
                onOpen={() => setEndTimePickerOpen(true)}
                onClose={() => setEndTimePickerOpen(false)}
                inputFormat={getLocaleFormat()}
                value={selectedEndDate}
                onChange={handleEndDateChange}
                renderDay={renderCustomDayPicker}
                renderInput={(params) => (
                  <StyledTextField
                    style={{ width: 176, marginLeft: 38, marginTop: 15 }}
                    {...params}
                    data-testid={publishEndDateTestId}
                  />
                )}
                label={t(`${i18Prefix}.end_date`)}
                PopperProps={popperProps}
              />
            </div>
          </LocalizationProvider>

          <TextField
            id='filled-multiline-flexible'
            label={t(`${i18Prefix}.message`)}
            multiline
            rows={4}
            value={messageState}
            onChange={handleMessageChange}
            fullWidth
            variant='outlined'
            sx={{
              '& .MuiInputBase-root': {
                padding: 0,
              },
            }}
          />
          <div
            style={{
              justifyContent: 'space-between',
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            {tempFeatureFlag && (
              <FormControlLabel
                value='false'
                control={
                  <Checkbox
                    color='primary'
                    checked={notifyState.notifyAllWithChanges}
                    onChange={handleNotifyChange}
                    name='notifyAllWithChanges'
                  />
                }
                label='Notify delivery partners with changes'
              />
            )}
            <FormControlLabel
              value='true'
              control={
                <Checkbox
                  color='primary'
                  checked={notifyState.notifyAll}
                  onChange={handleNotifyChange}
                  name='notifyAll'
                  style={{ padding: '9px' }}
                />
              }
              label={t(`${i18Prefix}.notify_all_delivery_partners`) as any}
            />
          </div>
        </DialogContent>
        {apiError ? <DialogContent style={{ color: 'red' }}>{apiError}</DialogContent> : null}
        {apiCallLoading ? <CircularProgress style={{ margin: 'auto' }} /> : null}
        <DialogActions
          style={{
            justifyContent: 'space-between',
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <SecondaryActionButton onClick={handleUnpublish}>
            {t(`${i18Prefix}.unpublish_all_available_hours`)}
          </SecondaryActionButton>
          <PrimaryActionButton data-testid={publishTestId} onClick={handlePublish}>
            {shiftUpdateLoading ? (
              <CircularProgress size={20} style={{ marginRight: '5px', color: 'white' }} />
            ) : undefined}
            {t(`${i18Prefix}.publish_changes`, {
              count: countUnpublishedShifts(shiftsToUpdate),
            })}
          </PrimaryActionButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { publishEndDateTestId, publishTestId };
export default PublishScheduleModalBody;
