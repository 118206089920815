const carIconTestId = 'directionsCarIcon';
const bikeIconTestId = 'directionsBikeIcon';
const footIconTestId = 'directionsFootIcon';
const mopedIconTestId = 'mopedOutlinedIcon';
const paginationBackButtonTestId = 'backButton';
const paginationNextButtonTestId = 'nextButton';
const closeModalTestId = 'closeModal';

export {
  carIconTestId,
  bikeIconTestId,
  footIconTestId,
  mopedIconTestId,
  paginationBackButtonTestId,
  paginationNextButtonTestId,
  closeModalTestId,
};
