import api from './api';

/**
 * Convenience function to fetch a list of all locations from the driver scheduling API
 *  Logging happens upstream
 *  Error handling happens downstream
 */
const getAllLocations = async () => {
  const response = await api.get<any[]>('/v2/manager/locations');
  return response.data;
};

/**
 * Convenience function to fetch location details for a set of locations
 * @param locationIds an array of locationIds to fetch
 *  Logging happens upstream
 *  Error handling happens downstream
 */
const getDetailedLocations = async (locationIds) => {
  if (!Array.isArray(locationIds)) throw new Error('Argument must be an array');
  const response = await api.get<any[]>('/v2/manager/locations/details', {
    params: {
      ids: locationIds.length ? locationIds.join(',') : undefined,
    },
  });
  return response.data;
};

export { getAllLocations, getDetailedLocations };
