const queryString = new URLSearchParams(window.location.search);
import { DateTime } from 'luxon';
import { atom, selector } from 'recoil';
import {
  comparePeopleAlphabetically,
  filterLocationsBySearch,
  filterPeopleBySearch,
  hideUnscheduled,
  sortPeopleByShifts,
} from '../utilities/Functions';
import { shiftColors } from '../utilities/Styles';
import {
  calendarViews,
  classifications,
  deliveryMethods,
  pages,
  sortByOptions,
} from '../utilities/Variables';
import { shiftDataState } from './ShiftState';

export const currentLocationState = atom({
  key: 'curentLocationState',
  default: {
    id: '',
    name: '',
    displayName: '',
    address: {},
    timeZone: '',
    shiftRequestEnabled: false,
  },
});

export const allLocationsState = atom({
  key: 'allLocationsState',
  default: [],
});

export const pageNumberState = atom({
  key: 'pageNumberState',
  default: 0,
});

export const detailedLocationsState = atom({
  key: 'detailedLocationsState',
  default: [],
});

export const viewUnscheduledState = atom({
  key: 'viewUnscheduledState',
  default: true,
});

export const calendarViewState = atom({
  key: 'calendarViewState',
  default: queryString.get('view') || calendarViews.daily.name(),
});

export const dateState = atom({
  key: 'dateState',
  default: sessionStorage.getItem('date')
    ? DateTime.fromISO(sessionStorage.getItem('date'), { setZone: true })
    : DateTime.now(),
});

export const classificationState = atom({
  key: 'classificationState',
  default: classifications,
});

export const deliveryMethodState = atom({
  key: 'deliveryMethodState',
  default: deliveryMethods,
});

const colorState = atom({
  key: 'colorState',
  default: shiftColors,
});

export const colorSelector = selector<string[]>({
  key: 'colorSelector',
  get: ({ get }) => {
    const colorList = get(colorState);
    return Object.keys(colorList).map((color) => colorList[color].name);
  },
});

export const peopleState = atom({
  key: 'peopleState',
  default: [],
});

export const searchBarState = atom({
  key: 'searchBarState',
  default: '',
});

export const hideUnscheduledState = atom({
  key: 'hideUnscheduledState',
  default: false,
});

export const hideUnscheduledPeople = selector({
  key: 'hideUnscheduledPeople',
  get: ({ get }) => {
    const peopleList = get(peopleState);
    const { assignedShifts } = get(shiftDataState);
    const isHideUnscheduled = get(hideUnscheduledState);
    return isHideUnscheduled ? hideUnscheduled(peopleList, assignedShifts) : peopleList;
  },
});

export const locationsFilteredBySearch = selector({
  key: 'locationsFilteredBySearch',
  get: ({ get }) => {
    const detailedLocations = get(detailedLocationsState);
    const searchBarText = get(searchBarState);
    if (searchBarText.length > 0) return filterLocationsBySearch(detailedLocations, searchBarText);
    return detailedLocations;
  },
});

export const peopleFilteredBySearch = selector({
  key: 'peopleFilteredBySearch',
  get: ({ get }) => {
    const peopleList = get(hideUnscheduledPeople);
    const searchBarText = get(searchBarState);
    if (searchBarText.length > 0) {
      // when on delivery partners page use optional parameter to also search on email
      return window.location.pathname === pages.deliveryPartners.route
        ? filterPeopleBySearch(peopleList, searchBarText, true)
        : filterPeopleBySearch(peopleList, searchBarText, undefined);
    }
    return peopleList;
  },
});

export const peopleSortedAlphabetically = selector({
  key: 'peopleSortedAlphabetically',
  get: ({ get }) => {
    const peopleList = get(peopleFilteredBySearch);
    if (peopleList.length > 0) return peopleList.slice().sort(comparePeopleAlphabetically);
    return peopleList;
  },
});

export const peopleSortedByShifts = selector({
  key: 'peopleSortedByShifts',
  get: ({ get }) => {
    const peopleList = get(peopleFilteredBySearch);
    const { assignedShifts } = get(shiftDataState);
    return sortPeopleByShifts(peopleList, assignedShifts);
  },
});

// Tracks the last action taken by the user.
export const userActionState = atom({
  key: 'userActionState',
  default: {
    userAction: '',
    actionCount: 0,
  },
});

export const sortByState = atom({
  key: 'sortByState',
  default: sortByOptions.SHIFTLIST,
});

export const queryStringState = atom({
  key: 'queryStringState',
  default: window.location.search,
});
