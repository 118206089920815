import { atom } from 'recoil';
import { deliveryMethods } from '../utilities/Variables';

const getDefaultDeliveryMethods = () => {
  const defaultKeys = {};
  for (const deliveryMethod in deliveryMethods) {
    defaultKeys[deliveryMethod] = true;
  }
  return defaultKeys;
};

export const allShiftsState = atom({
  key: 'allShiftsState',
  default: [],
});

export const shiftDataState = atom({
  key: 'shiftDataState',
  default: {
    availableShifts: {
      shifts: [],
    },
    requestableShifts: {
      shifts: [],
    },
    assignedShifts: {
      deliveryPartners: [],
      shifts: [],
    },
  },
});

export const deliveryMethodFilterState = atom({
  key: 'deliveryMethodFilterState',
  default: getDefaultDeliveryMethods(),
});

export const scheduleViewFilterState = atom({
  key: 'scheduleViewFilterState',
  default: 'all',
});
