import api from './api';

/**
 * Convenience function to fetch a list of all people for location from the driver scheduling API
 *  * @param locationId an singular locationId to fetch
 *  * @param cancelToken an axios cancel token sent to cancel current req if a new one comes
 *  Logging happens upstream
 *  Error handling happens downstream
 */
const getPeople = async (locationId, cancelToken) => {
  if (typeof locationId !== 'number') {
    throw new Error('Invalid location ID');
  }

  const response = await api.get<any[]>('/v2/manager/people', {
    params: {
      locationId,
    },
    cancelToken,
  });

  return response.data;
};

export { getPeople };
