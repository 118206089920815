import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SortIcon from '@mui/icons-material/Sort';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { sortByState } from '../../atoms/QueryState';
import { i18Prefix } from '../../i18Constants';
import '../../i18n';
import { formatDriverTotalHours, formatTotalHours } from '../../utilities/Dates';
import {
  colorPalette,
  footerCellStyle,
  headerCellStyle,
  IncreasedSpecificity,
  sharedStyles,
  sharedMeasurements,
  StickyFooterCell,
} from '../../utilities/Styles';
import { sortByOptions, tempFeatureFlag } from '../../utilities/Variables';

const titleCellTestId = 'titleCell';
const driverNameTestId = 'driverName';

const tableCellViewModes = {
  available: 'available',
  assigned: 'assigned',
};
const cellTypes = {
  header: 'header',
  footer: 'footer',
  data: 'data',
};

const leftPaddingTitle = 16;
const leftPaddingDriver = 24;
const rightPadding = 10;

const TitleColumnCell = styled(StickyFooterCell)({
  [IncreasedSpecificity]: {
    left: 0,
    zIndex: 3,
    paddingRight: rightPadding,
    minWidth: sharedMeasurements.titleColumnWidth,
    maxWidth: sharedMeasurements.titleColumnWidth * 2,
  },
});

const { centerVertical, noWrap } = sharedStyles;

const TitleCell = memo<any>((props) => {
  const { cellType } = props;
  const { mode } = props;
  const { rowHasData } = props;

  const { t } = useTranslation();

  const [sortBy, setSortBy] = useRecoilState(sortByState);

  function buildBorderRowTitleCell() {
    const { availableHoursSum } = cellType === cellTypes.footer ? props : 0;
    const { assignedHoursSum } = cellType === cellTypes.footer ? props : 0;

    const footerRowTitleCellExtraStyle =
      mode === tableCellViewModes.available ? {} : footerCellStyle;
    const borderRowTitleCellExtraStyle =
      cellType === cellTypes.header ? headerCellStyle : footerRowTitleCellExtraStyle;

    return (
      <TitleColumnCell
        data-testid={titleCellTestId}
        style={{
          ...borderRowTitleCellExtraStyle,
          zIndex: 5,
          backgroundColor: colorPalette.gray2,
          color: cellType !== cellTypes.data ? colorPalette.black : null,
        }}
        sx={{
          paddingLeft: `${leftPaddingTitle}px !important`,
        }}
      >
        {cellType === cellTypes.header ? (
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>{t(`${i18Prefix}.sort`)}</Grid>
            <Grid item>
              <Box color={colorPalette.gray5}>
                {props.showSort && (
                  <Tooltip
                    title={
                      <h3>
                        {sortBy === sortByOptions.SHIFTLIST
                          ? t(`${i18Prefix}.sort_alphabetically`)
                          : t(`${i18Prefix}.sort_by_hours`)}
                      </h3>
                    }
                    placement='top'
                  >
                    <IconButton
                      aria-label='edit'
                      size='small'
                      color='inherit'
                      onClick={() => {
                        setSortBy(
                          sortBy === sortByOptions.ALPHABET
                            ? sortByOptions.SHIFTLIST
                            : sortByOptions.ALPHABET,
                        );
                      }}
                    >
                      <SortIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction='column' alignItems='center'>
            <Grid
              item
              style={{
                borderBottom: '1px solid black',
              }}
            >
              {t(`${i18Prefix}.available_hours`)}: {formatTotalHours(availableHoursSum)}
            </Grid>
            <Grid item>
              {t(`${i18Prefix}.taken_hours`)}: {formatTotalHours(assignedHoursSum)}
            </Grid>
          </Grid>
        )}
      </TitleColumnCell>
    );
  }

  function buildDataRowTitleCell() {
    const { index } = props;
    const { length } = props;
    const { totalTimeInMinutes } = props;
    const { firstName } = mode === tableCellViewModes.available ? '' : props;
    const { lastName } = mode === tableCellViewModes.available ? '' : props;
    const { mustRequest } = props;

    const totalTimeInHours = totalTimeInMinutes / 60;

    const availableRowTitle = mustRequest
      ? t(`${i18Prefix}.request_hours`)
      : t(`${i18Prefix}.immediate_hours`);

    const dataRowTitleColorDefault =
      mode === tableCellViewModes.available ? colorPalette.gray6 : colorPalette.white;

    const dataRowTitleExtraStyle =
      mode === tableCellViewModes.available ? colorPalette.gray2 : null;
    const filledDataCellExtraStyles = rowHasData
      ? {
          borderTop: index !== 0 ? dataRowTitleColorDefault : sharedMeasurements.lightBorder,
          borderBottom:
            index !== length - 1 ? dataRowTitleColorDefault : sharedMeasurements.lightBorder,
        }
      : null;
    const availableCellExtraStyles =
      mode === tableCellViewModes.available
        ? {
            position: 'sticky',
            height: sharedMeasurements.innerCellHeight,
            top: sharedMeasurements.tableCellHeight * (index + 1) - 6 * index,
          }
        : null;

    const rightPaddingDriver =
      50 + 10 * Math.max(2, totalTimeInHours.toString().split('.')[0].length);

    return (
      <TitleColumnCell
        style={
          {
            backgroundColor: dataRowTitleColorDefault,
            ...filledDataCellExtraStyles,
            ...availableCellExtraStyles,
          } as any
        }
        sx={{
          paddingLeft: `${leftPaddingDriver}px !important`,
        }}
      >
        {index === 0 && (
          <>
            <Box
              data-testid={titleCellTestId}
              display='flex'
              alignItems='center'
              style={
                {
                  position: 'relative',
                  flex: 1,
                  height: sharedMeasurements.innerCellHeight,
                  color: rowHasData ? dataRowTitleExtraStyle : null,
                  ...noWrap,
                } as any
              }
            >
              <Box component='span' style={{ ...noWrap } as any}>
                <Box
                  data-testid={mode === tableCellViewModes.available ? undefined : driverNameTestId}
                  component='span'
                  style={
                    rowHasData || mode === tableCellViewModes.available
                      ? {
                          position: 'relative',
                          color: mode === tableCellViewModes.available ? colorPalette.gray2 : null,
                          paddingRight: rightPaddingDriver,
                        }
                      : { paddingRight: rightPaddingDriver }
                  }
                >
                  {mode === tableCellViewModes.available
                    ? availableRowTitle
                    : `${firstName} ${lastName}`}
                </Box>
              </Box>

              <Box
                component='span'
                style={
                  {
                    zIndex: 2,
                    position: 'absolute',
                    right: 0,
                    backgroundColor: dataRowTitleColorDefault,
                    paddingLeft: 6,
                    color: dataRowTitleExtraStyle,
                    ...centerVertical,
                    ...noWrap,
                    ...(tempFeatureFlag
                      ? {
                          top: 1,
                          minWidth: 60,
                        }
                      : {
                          paddingRight: 5,
                        }),
                  } as any
                }
              >
                <Grid container direction='row' alignItems='center'>
                  <Grid
                    item
                    style={{
                      color: mode === tableCellViewModes.available ? colorPalette.gray2 : null,
                    }}
                  >
                    {formatDriverTotalHours(totalTimeInHours)}
                  </Grid>
                  {tempFeatureFlag && (
                    <Grid item>
                      {/* TODO: add onclick */}
                      <IconButton size='small' color='inherit'>
                        <MoreHorizIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </TitleColumnCell>
    );
  }

  return cellType === cellTypes.data ? buildDataRowTitleCell() : buildBorderRowTitleCell();
});

(TitleCell as any).propTypes = {
  cellType: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  rowHasData: PropTypes.bool.isRequired,
  index: PropTypes.number,
  length: PropTypes.number,
  totalTimeInMinutes: PropTypes.number,
  availableHoursSum: PropTypes.number,
  assignedHoursSum: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  showSort: PropTypes.bool,
  mustRequest: PropTypes.bool,
};
(TitleCell as any).defaultProps = {
  index: 0,
  length: 0,
  totalTimeInMinutes: 0,
  availableHoursSum: 0,
  assignedHoursSum: 0,
  firstName: '',
  lastName: '',
  showSort: false,
  mustRequest: false,
};

export { titleCellTestId, driverNameTestId, cellTypes, tableCellViewModes };
export default TitleCell;
