export const popperProps = {
  popperOptions: {
    modifiers: [
      {
        name: 'computeStyles',
        enabled: true,
        fn({ state }) {
          state.styles.popper = {
            ...state.styles.popper,
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          };
          return state;
        },
      },
    ],
  },
};
