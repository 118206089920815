import axios from 'axios';
import { DateTime } from 'luxon';
import config from '../config';
import oktaAuth from './okta';

const api = axios.create({
  baseURL: config.DRIVER_SCHEDULING_API,
});

api.interceptors.request.use(async (request) => {
  let authState = oktaAuth?.authStateManager?.getAuthState();
  let oktaToken = authState?.accessToken?.accessToken;
  if (!oktaToken) {
    console.log('Okta token not set', authState);
    throw new axios.Cancel('Okta token not set');
  }
  const tokenExpirationDate = DateTime.fromMillis((authState?.accessToken?.expiresAt - 5) * 1000);
  if (tokenExpirationDate < DateTime.now()) {
    await oktaAuth.tokenManager.renew('accessToken');
    authState = oktaAuth?.authStateManager?.getAuthState();
    oktaToken = authState?.accessToken?.accessToken;
  }
  request.headers.Authorization = `Bearer ${oktaToken}`;
  return request;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error && error.response && error.response.data) {
      console.log(
        `API request error. Status Code:${error.response.status} Message:${error.response.data.message}`,
        error.response.data,
      );
      throw new Error(error.response.data.message);
    } else if (error) {
      console.log(
        'API request error. Status Code:500 Message:Unspecified API request error',
        error,
      );
      if (error.message === 'Request canceled') throw new Error(error.message);
      else throw new Error('Unknown API request error. Please contact support.');
    }
  },
);

export default api;
