import { DirectionsBike, DirectionsCar, DirectionsWalk, MopedOutlined } from '@mui/icons-material';
import i18n from 'i18next';
import { i18Prefix } from '../i18Constants';
import '../i18n';
import { bikeIconTestId, carIconTestId, footIconTestId, mopedIconTestId } from './TestIds';

const pageState = {
  loading: 'loading',
  done: 'done',
  error: 'error',
};

const calendarViews = {
  daily: {
    name: () => i18n.t(`${i18Prefix}.daily_view`),
    value: 1,
  },
  weekly: {
    name: () => i18n.t(`${i18Prefix}.weekly_view`),
    value: 2,
  },
};

const pages = {
  scheduler: {
    route: '/scheduler',
    title: () => i18n.t(`${i18Prefix}.scheduler`),
  },
  deliveryPartners: {
    route: '/delivery-partners',
    title: () => i18n.t(`${i18Prefix}.delivery_partners`),
  },
  locations: {
    route: '/locations',
    title: () => i18n.t(`${i18Prefix}.locations`),
  },
};

const modals = {
  createModal: 'create',
  editModal: 'edit',
  publishModal: 'publish',
  editDeliveryPartnerModal: 'deliveryPartner',
  blockListModal: 'blockListModal',
};

const classifications = {
  driver: {
    id: 1,
    name: () => i18n.t(`${i18Prefix}.driver`),
    value: 'driver',
  },
  // core: {
  //   id: 2,
  //   name: () => i18n.t(`${i18Prefix}.core`),
  //   value: 'core',
  // },
  // newDriver: {
  //   id: 3,
  //   name: () => i18n.t(`${i18Prefix}.new_driver`),
  //   value: 'newDriver',
  // },
};

// map primary action state to button text
const primaryActionText = {
  publish: (count) => i18n.t(`${i18Prefix}.publish_changes_notify`, { count }),
  unpublish: () => i18n.t(`${i18Prefix}.unpublish_notify`),
  notify: () => i18n.t(`${i18Prefix}.resend_notification`),
};

const paginationOptions = [20, 30, 40];

// NOTE: temporary.  replace with call to api
// TODO: create call to back end for flag
const tempFeatureFlag = false;

const userActions = {
  publish: 'publish',
  unpublish: 'unpublish',
  createAndSave: 'createAndSave',
  createAndPublish: 'createAndPublish',
  editAndSave: 'editAndSave',
  editAndPublish: 'editAndPublish',
  delete: 'delete',
  autoRefresh: 'autoRefresh',
  clickedAppBar: 'clickedAppBar',
};

const deliveryMethods = {
  car: {
    id: 1,
    name: () => i18n.t(`${i18Prefix}.motor_vehicle`),
    value: 'car',
    icon: <DirectionsCar data-testid={carIconTestId} fontSize='small' />,
  },
  bike: {
    id: 2,
    name: () => i18n.t(`${i18Prefix}.bike`),
    value: 'bike',
    icon: <DirectionsBike data-testid={bikeIconTestId} fontSize='small' />,
  },
  foot: {
    id: 3,
    name: () => i18n.t(`${i18Prefix}.walker`),
    value: 'foot',
    icon: <DirectionsWalk data-testid={footIconTestId} fontSize='small' />,
  },
  moped: {
    id: 4,
    name: () => i18n.t(`${i18Prefix}.moped`),
    value: 'moped',
    icon: <MopedOutlined data-testid={mopedIconTestId} fontSize='small' />,
  },
};

const scheduleViews = ['all', 'immediate', 'request', 'assigned'];

// Interval at which the scheduler page automatically refreshes data (in MS)
const schedulerRefreshTimeout = 300000; // 300 seconds

const faqURL =
  'https://docs.google.com/document/d/1EQxZb9N_ys7rQC-g642TUHqeOoR4BfHlMvD_Gb2K56k/edit?usp=sharing';

const sortByOptions = {
  ALPHABET: 'alphabet',
  SHIFTLIST: 'shiftList',
};

const locationsBlank = [{ id: null, name: '', displayName: '', address: {}, timeZone: '' }];

export {
  pageState,
  calendarViews,
  pages,
  classifications,
  modals,
  primaryActionText,
  paginationOptions,
  tempFeatureFlag,
  userActions,
  deliveryMethods,
  scheduleViews,
  schedulerRefreshTimeout,
  faqURL,
  sortByOptions,
  locationsBlank,
};
