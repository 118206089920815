import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { pageLoadingState } from '../atoms/PageState';
import {
  allLocationsState,
  detailedLocationsState,
  locationsFilteredBySearch,
  searchBarState,
} from '../atoms/QueryState';
import { i18Prefix } from '../i18Constants';
import '../i18n';
import { getDetailedLocations } from '../services/location';
import {
  colorPalette,
  sharedStyles,
  StyledTable,
  StyledTablePagination,
  DataTableLeftText,
  AppSpacerBox,
  DataTableCell,
  DataTableContainer,
  DataTableHeader,
  FillVerticalBox,
  PaginationTableContainer,
} from '../utilities/Styles';
import { deliveryMethods, pages, pageState, paginationOptions } from '../utilities/Variables';
import { paginationBackButtonTestId, paginationNextButtonTestId } from '../utilities/TestIds';
import PageHeader from './PageHeader';

const LocationsTestId = 'deliveryPartners';

const { centerVertical, noWrap } = sharedStyles;

const Locations = (props) => {
  const [pageLoading, setPageLoading] = useRecoilState(pageLoadingState);

  const allLocations = useRecoilValue(allLocationsState);
  const setSearchBarState = useSetRecoilState(searchBarState);
  const filteredLocations = useRecoilValue(locationsFilteredBySearch);
  const [apiError, setApiError] = useState(undefined);
  // TODO: extract next 3 lines (copied on scheduler page)
  const setDetailedLocations = useSetRecoilState(detailedLocationsState);
  const [page, setPage] = useState(0);
  // NOTE: pagination default of 30 rows
  const [rowsPerPage, setRowsPerPage] = useState(paginationOptions[1]);

  const { t } = useTranslation();

  useEffect(() => {
    if (!props.isTest) setSearchBarState(''); // page reload should reset search text, except when testing
    if (!allLocations.length) return;
    async function fetchData() {
      try {
        const response = await getDetailedLocations([]);
        setDetailedLocations(
          response.sort((a, b) => (a.displayName ?? '').localeCompare(b.displayName ?? '')),
        );
        setPageLoading(pageState.done);
      } catch (error) {
        console.log(error);
        setPageLoading(pageState.error);
        setApiError(error.message || t(`${i18Prefix}.api_failure`));
      }
    }

    fetchData();
  }, [allLocations]);

  // TODO: extract these two functions and several components below (copies)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <FillVerticalBox data-testid={LocationsTestId}>
      <AppSpacerBox />

      <PageHeader view={pages.deliveryPartners.title()} />

      <DataTableContainer
        style={
          {
            ...(pageLoading !== pageState.done && centerVertical),
          } as any
        }
      >
        {/* TODO: show over table data to smooth transitions */}
        {pageLoading === pageState.loading && (
          <CircularProgress
            size={60}
            style={{
              margin: 'auto',
            }}
          />
        )}
        {pageLoading === pageState.error && (
          <Card
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              color: colorPalette.statusRed,
            }}
          >
            {apiError}
          </Card>
        )}
        {pageLoading === pageState.done && (
          <StyledTable stickyHeader size='small' style={{ borderCollapse: 'separate' }}>
            <TableHead>
              <TableRow>
                <DataTableHeader>
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <DataTableLeftText>{t(`${i18Prefix}.location`)}</DataTableLeftText>
                    </Grid>
                    <Grid item>
                      <Box color={colorPalette.gray5}>
                        <IconButton size='small' color='inherit'>
                          <SortIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.internal_name`)}</DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.address`)}</DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.delivery_methods`)}</DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.timezone`)}</DataTableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO: add sorting support */}
              {filteredLocations?.length > 0 &&
                filteredLocations
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((location, index) => (
                    <TableRow key={`${JSON.stringify(location)}-${index}`}>
                      <DataTableCell
                        component='th'
                        align='left'
                        scope='row'
                        padding='none'
                        style={
                          {
                            position: 'relative',
                            ...noWrap,
                          } as any
                        }
                      >
                        <DataTableLeftText
                          style={
                            {
                              ...centerVertical,
                              ...noWrap,
                            } as any
                          }
                        >
                          {location.displayName}
                        </DataTableLeftText>
                      </DataTableCell>
                      <DataTableCell align='left'>{location.name}</DataTableCell>
                      <DataTableCell align='left'>
                        {`${location.address.address} ${location.address.city}, ${location.address.state}, ${location.address.zip}`}
                      </DataTableCell>
                      <DataTableCell align='left'>
                        <Grid container spacing={1}>
                          {(location.deliveryMethods ?? []).map((deliveryMethod) => (
                            <Grid item key={JSON.stringify(deliveryMethod)}>
                              {deliveryMethods[deliveryMethod]?.icon}
                            </Grid>
                          ))}
                        </Grid>
                      </DataTableCell>
                      <DataTableCell align='left'>{location.timeZone}</DataTableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </StyledTable>
        )}
      </DataTableContainer>
      <PaginationTableContainer>
        <Table>
          <TableBody>
            <TableRow>
              {/* TODO: change display.  reduce distance to bottom of table */}
              <StyledTablePagination
                rowsPerPageOptions={paginationOptions}
                count={filteredLocations.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={`${t(`${i18Prefix}.rows_per_page`)}:`}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${t(`${i18Prefix}.of`)} ${count}`
                }
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={
                  {
                    'data-testid': paginationBackButtonTestId,
                  } as any
                }
                nextIconButtonProps={
                  {
                    'data-testid': paginationNextButtonTestId,
                  } as any
                }
              />
            </TableRow>
          </TableBody>
        </Table>
      </PaginationTableContainer>
    </FillVerticalBox>
  );
};

export { LocationsTestId };
export default Locations;
