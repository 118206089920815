import BuildIcon from '@mui/icons-material/Build';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker, PickersDayProps } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Button, FormControl, Grid, IconButton, Input, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { modalState } from '../atoms/ModalState';
import { pageLoadingState, primaryActionState } from '../atoms/PageState';
import { calendarViewState, currentLocationState, dateState } from '../atoms/QueryState';
import { allShiftsState } from '../atoms/ShiftState';
import { i18Prefix } from '../i18Constants';
import '../i18n';
import { formatDateRange, formatSingleDay, getWeekFromDay } from '../utilities/Dates';
import { countUnpublishedShifts } from '../utilities/Functions';
import {
  colorPalette,
  getDateTimePaperPropsStyles,
  IncreasedSpecificity,
  PrimaryActionButton,
  renderCustomDayPicker,
  sharedMeasurements,
} from '../utilities/Styles';
import {
  calendarViews,
  modals,
  pages,
  pageState,
  primaryActionText,
  tempFeatureFlag,
} from '../utilities/Variables';
import { popperProps } from '../utilities/Props';
import { DateTime } from 'luxon';

const pageHeaderTestId = 'pageHeader';
const setDateTestId = 'setDateButton';
const calendarPopperTestId = 'calendarPopper';
const forwardButtonTestId = 'forwardButton';
const pageHeaderBackButtonTestId = 'pageHeaderBackButton';

const sideSpacer = 12;

const restrictHeightStyle = {
  minHeight: sharedMeasurements.headerItemHeight,
  maxHeight: sharedMeasurements.headerItemHeight,
};
const restrictDimensionStyle = {
  ...restrictHeightStyle,
  minWidth: sharedMeasurements.headerItemHeight,
  maxWidth: sharedMeasurements.headerItemHeight,
};
const buttonStyle = {
  color: colorPalette.gray6,
  backgroundColor: colorPalette.gray2,
};

const CalendarViewTextField = styled<any>(TextField)({
  '& .MuiOutlinedInput-input': {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: sharedMeasurements.headerItemHeight,
  },
});

const PageHeaderButton = styled(Button)({
  [IncreasedSpecificity]: {
    ...buttonStyle,
  },
});

const ForwardBackButton = styled(PageHeaderButton)({
  [IncreasedSpecificity]: {
    ...restrictDimensionStyle,
    paddingBottom: 9,
  },
});

const TodayButton = styled(PageHeaderButton)({
  ...restrictHeightStyle,
});

const BoxButton = styled(Box)({
  ...restrictDimensionStyle,
  backgroundColor: colorPalette.gray2,
  borderRadius: sharedMeasurements.basicButtonRadius,
  padding: 0,
  cursor: 'pointer',
  marginLeft: 1,
  marginRight: 1,
});

const HeadTileBox = styled(Box)({
  border: sharedMeasurements.standardBorder,
  borderRadius: sharedMeasurements.standardBorderRadius,
  marginTop: 16,
  paddingLeft: sideSpacer,
  paddingRight: sideSpacer,
  maxHeight: 50,
  minHeight: 50,
  backgroundColor: colorPalette.white,
});

const PrimaryTextGrid = styled(Grid)({
  fontSize: 14,
  color: colorPalette.gray7,
});

const SecondaryTextGrid = styled(Grid)({
  fontSize: 11,
  color: colorPalette.gray6,
  opacity: '74%',
});

const ButtonGrid = styled(Grid)({
  [IncreasedSpecificity]: {
    paddingLeft: 0,
  },
});

const PageHeader = (props) => {
  const { t } = useTranslation();

  const [calendarView, setCalendarView] = useRecoilState(calendarViewState);
  const [date, setDate] = useRecoilState(dateState);

  const primaryAction = useRecoilValue(primaryActionState);
  const currentLocation = useRecoilValue(currentLocationState);
  const shiftData = useRecoilValue(allShiftsState);

  const setDisplayModal = useSetRecoilState<any>(modalState);
  const setPageLoading = useSetRecoilState(pageLoadingState);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  // TODO: implement notifications functionality
  const notify = () => {
    console.log('Notifying drivers');
  };

  const changeCalendarView = (event) => {
    setPageLoading(pageState.loading);

    setCalendarView(event.target.value);
  };

  const setDateForward = () => {
    setPageLoading(pageState.loading);

    if (calendarView === calendarViews.weekly.name()) {
      const newDate = date.plus({ weeks: 1 });
      setDate(newDate);
    } else {
      const newDate = date.plus({ days: 1 });
      setDate(newDate);
    }
  };

  const setDateBack = () => {
    setPageLoading(pageState.loading);

    if (calendarView === calendarViews.weekly.name()) {
      const newDate = date.minus({ weeks: 1 });
      setDate(newDate);
    } else {
      const newDate = date.minus({ days: 1 });
      setDate(newDate);
    }
  };

  const setDateCurrent = () => {
    setPageLoading(pageState.loading);

    const newDate = DateTime.now().setZone(currentLocation.timeZone || 'local');
    const newCalendarView = calendarViews.daily.name();
    setDate(newDate);
    setCalendarView(newCalendarView);
  };

  const setDateFromCalendar = (selectedDate: DateTime) => {
    setPageLoading(pageState.loading);

    const tempDate = DateTime.now()
      .setZone(currentLocation.timeZone || 'local')
      .startOf('day');

    const computedDate = tempDate.set({
      year: selectedDate.year,
      month: selectedDate.month,
      day: selectedDate.day,
    });

    setDate(computedDate);
  };

  const formatTitleText = () => {
    if (date && currentLocation.timeZone) {
      if (calendarView === calendarViews.weekly.name()) {
        const week = getWeekFromDay(date, currentLocation.timeZone);
        return formatDateRange(week[0], week[week.length - 1]);
      }
      return formatSingleDay(date, currentLocation.timeZone);
    }
    return null;
  };

  const buildDatePicker = () => (
    <Box
      style={{
        position: 'absolute',
        left: '50%',
        WebkitTransform: 'translateX(-50%)',
        transform: 'translateX(-50%)',
        top: 9,
      }}
    >
      <Grid container alignItems='center' spacing={1}>
        <Grid item>
          <TodayButton onClick={setDateCurrent} data-testid='todayButton'>
            {t(`${i18Prefix}.today`)}
          </TodayButton>
        </Grid>
        <Grid item>
          <FormControl>
            <CalendarViewTextField
              select
              id='CalendarView'
              displayempty='false'
              variant='outlined'
              value={calendarView}
              onChange={changeCalendarView}
              SelectProps={{
                native: true,
                style: { padding: 5 },
              }}
            >
              {Object.keys(calendarViews).map((key) => (
                <option key={key} value={calendarViews[key].name()}>
                  {calendarViews[key].name()}
                </option>
              ))}
            </CalendarViewTextField>
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container justifyItems='center' spacing={1}>
            <Grid item>
              <ForwardBackButton data-testid={pageHeaderBackButtonTestId} onClick={setDateBack}>
                {'<'}
              </ForwardBackButton>
            </Grid>

            <ButtonGrid
              item
              sx={{
                '&& .MuiGrid-item': {
                  paddingLeft: 0,
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
                <DesktopDatePicker
                  disableMaskedInput
                  value={DateTime.fromObject({
                    year: date.year,
                    month: date.month,
                    day: date.day,
                  }).toISO()}
                  open={isDatePickerOpen}
                  onClose={() => setIsDatePickerOpen(false)}
                  onChange={(date) => setDateFromCalendar(DateTime.fromISO(date))}
                  renderDay={(
                    day: string,
                    selectedDays: string[],
                    pickersDayProps: PickersDayProps<string>,
                  ) => {
                    const tempDate = DateTime.now().setZone(currentLocation.timeZone);
                    const currentDay = DateTime.fromObject({
                      year: tempDate.year,
                      month: tempDate.month,
                      day: tempDate.day,
                    });

                    // NOTE: so that selectedDays uses the current timezone with the selected date
                    selectedDays = [
                      DateTime.fromObject({
                        year: date.year,
                        month: date.month,
                        day: date.day,
                      }).toISO(),
                    ];

                    const calendarDay = DateTime.fromISO(pickersDayProps.day);
                    if (currentDay.toMillis() === calendarDay.toMillis()) {
                      pickersDayProps.today = true;
                    } else {
                      pickersDayProps.today = false;
                    }

                    let dayIsSelected = false;
                    if (selectedDays.includes(calendarDay.toISO())) {
                      dayIsSelected = true;
                    } else {
                      dayIsSelected = false;
                    }
                    pickersDayProps.selected = dayIsSelected;
                    pickersDayProps['aria-selected'] = dayIsSelected;

                    return renderCustomDayPicker(day, selectedDays, pickersDayProps);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <BoxButton
                      component='div'
                      data-testid={setDateTestId}
                      display='flex'
                      onClick={() => setIsDatePickerOpen((isOpen) => !isOpen)}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          padding: 0,
                          width: '0px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderWidth: '0px',
                        },
                        '& .Mui-selected': {
                          backgroundColor: colorPalette.blue6,
                        },
                        '& .MuiPickersDay-root': {
                          backgroundColor: colorPalette.blue6,
                        },
                        '& .MuiPickersDay-today': {
                          backgroundColor: colorPalette.blue6,
                        },
                        '& .MuiInputAdornment-root': {
                          ...restrictDimensionStyle,
                          height: 0,
                          marginLeft: 0,
                        },
                        '& .MuiButtonBase-root': {
                          ...restrictDimensionStyle,
                          paddingLeft: 4,
                          ':hover': buttonStyle,
                        },
                      }}
                    >
                      {/* NOTE: height and width 0 instead of display: none */}
                      {/* CONT: display: none creates an error with a bad input prop */}
                      <Input
                        style={{ height: 0, width: 0 }}
                        ref={inputRef}
                        {...(inputProps as any)}
                      />
                      {InputProps?.endAdornment}
                    </BoxButton>
                  )}
                  PopperProps={{ 'data-testid': calendarPopperTestId, ...popperProps } as any}
                  PaperProps={getDateTimePaperPropsStyles()}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: 0,
                    },
                  }}
                  OpenPickerButtonProps={{
                    style: {
                      margin: 0,
                      padding: 0,
                    },
                  }}
                />
              </LocalizationProvider>
            </ButtonGrid>

            <ButtonGrid item>
              <ForwardBackButton data-testid={forwardButtonTestId} onClick={setDateForward}>
                {'>'}
              </ForwardBackButton>
            </ButtonGrid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const buildPrimaryActionButton = () => (
    <Box
      component='span'
      className='showOnHover'
      style={{
        position: 'absolute',
        right: 7,
        top: 5,
      }}
    >
      <Grid container alignItems='center' spacing={1}>
        {tempFeatureFlag && (
          <Grid item>
            {/* TODO: add on-click */}
            <IconButton {...({} as any)} size='small' fontSize='medium'>
              <BuildIcon fontSize='inherit' />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <PrimaryActionButton
            onClick={() => {
              if (primaryAction === 'publish' || primaryAction === 'unpublish') {
                setDisplayModal({ open: true, modal: modals.publishModal });
              } else notify();
            }}
          >
            {primaryActionText[primaryAction](countUnpublishedShifts(shiftData))}
          </PrimaryActionButton>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <HeadTileBox
      data-testid={pageHeaderTestId}
      display='flex'
      alignItems='center'
      style={{
        paddingLeft: 6,
        position: 'relative',
      }}
    >
      <Box>
        <Grid container alignItems='center' spacing={1}>
          <PrimaryTextGrid item>
            <Box style={{ marginLeft: 7 }}>{formatTitleText()}</Box>
          </PrimaryTextGrid>
          <SecondaryTextGrid item>
            {currentLocation.displayName || currentLocation.name || ''}
          </SecondaryTextGrid>
        </Grid>
      </Box>

      {props.view === pages.scheduler.title() && buildDatePicker()}

      {(tempFeatureFlag || primaryAction !== 'notify') &&
        props.view === pages.scheduler.title() &&
        buildPrimaryActionButton()}
    </HeadTileBox>
  );
};

PageHeader.propTypes = {
  view: PropTypes.string.isRequired,
};

export {
  pageHeaderTestId,
  setDateTestId,
  calendarPopperTestId,
  forwardButtonTestId,
  pageHeaderBackButtonTestId,
};
export default PageHeader;
