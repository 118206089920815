import { Box, Modal } from '@mui/material';
import { styled, StyledEngineProvider, ThemeProvider, useTheme } from '@mui/material/styles';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security } from '@okta/okta-react';
import { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import './App.css';
import { modalState } from './atoms/ModalState';
import { drawerState } from './atoms/PageState';
import {
  calendarViewState,
  currentLocationState,
  dateState,
  queryStringState,
} from './atoms/QueryState';
import AppHeader from './components/AppHeader';
import DeliveryPartners from './components/DeliveryPartners';
import EditDeliveryPartnerModalBody from './components/deliveryPartners/EditDeliveryPartnerModalBody';
import Healthz from './components/Healthz';
import Locations from './components/Locations';
import LoginCallback from './components/LoginCallback';
import NotFound from './components/NotFound';
import Scheduler from './components/Scheduler';
import PublishScheduleModalBody from './components/scheduler/PublishScheduleModalBody';
import ShiftModalBody from './components/scheduler/ShiftModalBody';
import SideBar from './components/SideBar';
import oktaAuth from './services/okta';
import { multiClass } from './utilities/Extensions';
import { sharedMeasurements } from './utilities/Styles';
import { modals, schedulerRefreshTimeout } from './utilities/Variables';

const StyledBox = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const App = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const currentLocation = useRecoilValue(currentLocationState);
  const drawer = useRecoilValue(drawerState);
  const [displayModal, setDisplayModal] = useRecoilState(modalState);
  const setQueryString = useSetRecoilState(queryStringState);

  const hideModal = { open: false, modal: '', modalObject: {} };

  const restoreOriginalUri = useCallback(async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  }, []);

  const date = useRecoilValue(dateState);
  const calendarView = useRecoilValue(calendarViewState);

  // When date, calendar view, or location changes, update external state
  useEffect(() => {
    if (location.pathname === '/healthz') return;
    sessionStorage.setItem('date', date.toISO()); // date saved with session storage
    const url = new URL(window.location as any);
    url.searchParams.set('view', calendarView);
    if (currentLocation.id) {
      url.searchParams.set('location', currentLocation.displayName || currentLocation.name);
    }
    window.history.replaceState({}, '', url);
    setQueryString(url.search);
  }, [date, calendarView, currentLocation]);

  const renderPage = (component) => (
    <Box>
      <AppHeader />
      <SideBar />
      <StyledBox
        sx={{
          width: '100%',
          position: 'fixed',
        }}
      >
        <StyledBox
          className={multiClass([])}
          style={{
            marginLeft: drawer ? sharedMeasurements.drawerWidth : null,
            paddingLeft: sharedMeasurements.standardSpacing,
            paddingRight: sharedMeasurements.standardSpacing,
            position: 'relative',
          }}
        >
          {component}
        </StyledBox>
      </StyledBox>
      <Modal
        open={displayModal.open}
        onClose={() => {
          setDisplayModal(hideModal);
        }}
      >
        <Box>
          {(displayModal.modal === modals.createModal ||
            displayModal.modal === modals.editModal) && <ShiftModalBody />}
          {displayModal.modal === modals.publishModal && <PublishScheduleModalBody />}
          {displayModal.modal === modals.editDeliveryPartnerModal && (
            <EditDeliveryPartnerModalBody />
          )}
        </Box>
      </Modal>
    </Box>
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Switch>
            <Route exact path='/login/callback' render={(props) => <LoginCallback {...props} />} />
            <SecureRoute exact path='/'>
              <Redirect to='/scheduler' />
            </SecureRoute>
            <SecureRoute
              path='/scheduler'
              render={() => renderPage(<Scheduler timeoutLength={schedulerRefreshTimeout} />)}
            />
            <SecureRoute
              path='/delivery-partners'
              render={() => renderPage(<DeliveryPartners />)}
            />
            <SecureRoute path='/locations' render={() => renderPage(<Locations />)} />
            <Route exact path='/healthz' render={() => <Healthz />} />
            <SecureRoute render={() => renderPage(<NotFound />)} />
          </Switch>
        </Security>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
