import api from './api';

/**
 * Function to get shifts for a given location
 * @param getShiftsObject object to hold the below parameters to call getShifts with
 * @param startTime return shifts that start on or after this ISO time string
 * @param endTime return shifts that end on or before this ISO time string
 * @param locationId id of location for which to return shifts
 * @param tags optional array of tags to filter on
 * @param cancelToken optional token sent to cancel current call if new one comes in
 *  Logging happens upstream
 *  Error handling happens downstream
 */
const getShifts = async (getShiftsObject) => {
  const { startTime, endTime, locationId, tags, cancelToken } = getShiftsObject;
  if (!startTime || !endTime || !locationId) throw new Error('Missing required query string param');
  const response = await api.get<any>('/v2/manager/shifts', {
    params: {
      startTime,
      endTime,
      locationId,
      tags,
    },
    cancelToken,
  });
  return response.data;
};

/**
 * Function to delete one to many shifts
 * @param shiftIds an array of shiftIds to delete
 *  Logging happens upstream
 *  Error handling happens downstream
 */
const deleteShifts = async (shiftIds) => {
  if (!Array.isArray(shiftIds) || shiftIds.length < 1) throw new Error('Invalid object to delete');
  const response = await api.delete('/v2/manager/shifts', {
    data: shiftIds,
  });
  return response.data;
};

/**
 * Function to create one to many shifts
 * @param shifts an array of shift objects to create
 *  Logging happens upstream
 *  Error handling happens downstream
 */
const createShifts = async (shifts) => {
  if (!Array.isArray(shifts) || shifts.length < 1)
    throw new Error('Invalid shifts array object to create');
  const response = await api.post('/v2/manager/shifts', shifts);
  return response.data;
};

/**
 * Function to edit one shift
 * @param shifts an array of shift objects to create
 *  Logging happens upstream
 *  Error handling happens downstream
 */
const editShifts = async (shifts) => {
  if (!Array.isArray(shifts) || shifts.length < 1)
    throw new Error('Invalid shift array object to edit');
  const response = await api.patch('/v2/manager/shifts', shifts);
  return response.data;
};

export { getShifts, deleteShifts, createShifts, editShifts };
