import { Card } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { colorPalette } from '../utilities/Styles';

const ErrorCard = ({ displayError }) => (
  <Card
    style={{
      marginTop: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      color: colorPalette.statusRed,
    }}
  >
    {displayError}
  </Card>
);

const LoginCallback = (props) => {
  const { onAuthResume } = props;
  const { oktaAuth, authState } = useOktaAuth();

  const [callbackError, setCallbackError] = useState(null);

  useEffect(() => {
    try {
      if (onAuthResume && oktaAuth.idx.isInteractionRequired?.()) {
        onAuthResume();
        return;
      }
      if (authState?.isAuthenticated) {
        window.location.replace('/scheduler');
      }
      if ((authState as any)?.pending || authState?.isAuthenticated) {
        return;
      }
      oktaAuth.handleLoginRedirect().catch((err) => handleLoginError(err));
    } catch (err) {
      handleLoginError(err);
    }
  }, [authState]);

  const handleLoginError = (err) => {
    console.log(err);
    setCallbackError(err);
  };

  const displayError = callbackError?.message || authState?.error;

  return displayError ? <ErrorCard displayError={displayError} /> : null;
};

export default LoginCallback;
