import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import EventNoteIcon from '@mui/icons-material/EventNote';
import SortIcon from '@mui/icons-material/Sort';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { DateTime } from 'luxon';
import { modalState } from '../atoms/ModalState';
import { pageLoadingState } from '../atoms/PageState';
import {
  peopleFilteredBySearch,
  peopleState,
  searchBarState,
  currentLocationState,
} from '../atoms/QueryState';
import { i18Prefix } from '../i18Constants';
import '../i18n';
import { getPeople } from '../services/people';
import {
  colorPalette,
  sharedStyles,
  StyledTable,
  StyledTablePagination,
  DataTableCheckBox,
  DataTableLeftText,
  DataTableRightText,
  AppSpacerBox,
  DataTableCell,
  DataTableContainer,
  DataTableHeader,
  FillVerticalBox,
  PaginationTableContainer,
} from '../utilities/Styles';
import {
  modals,
  pages,
  pageState,
  paginationOptions,
  tempFeatureFlag,
} from '../utilities/Variables';
import { paginationBackButtonTestId, paginationNextButtonTestId } from '../utilities/TestIds';
import PageHeader from './PageHeader';
import { formatSingleDay } from '../utilities/Dates';
import { reorderPeoplesCurrentLocation, sortPeople } from '../utilities/Functions';

const DeliveryPartnersTestId = 'deliveryPartners';

const { noWrap, centerVertical } = sharedStyles;

const DeliveryPartners = (props) => {
  const { t } = useTranslation();

  const [pageLoading, setPageLoading] = useRecoilState(pageLoadingState);
  const currentLocation = useRecoilValue(currentLocationState);
  const filteredPeople = useRecoilValue(peopleFilteredBySearch);

  const setDisplayModal = useSetRecoilState<any>(modalState);
  const setPeople = useSetRecoilState(peopleState);
  const setSearchBarState = useSetRecoilState(searchBarState);

  const [apiError, setApiError] = useState(undefined);
  const [page, setPage] = useState(0);
  // NOTE: keeps track of selected items within table.
  // NOTE: no use case for these items yet
  // TODO: clear on page change or amend to keep track between pages
  const [selected, setSelected] = useState([]);
  // NOTE: pagination default of 30 rows
  const [rowsPerPage, setRowsPerPage] = useState(paginationOptions[1]);

  useEffect(() => {
    if (!props.isTest) setSearchBarState(''); // page reload or location change should reset search text, except when testing
    if (!currentLocation.id) return;

    let canceled = false; // avoid race condition
    async function updateTableData() {
      try {
        const response = await getPeople(currentLocation.id, null);
        if (canceled) return;
        sortPeople(response);
        reorderPeoplesCurrentLocation(response, currentLocation);
        setPeople(response);
        setPageLoading(pageState.done);
      } catch (error) {
        console.log(error);
        setPageLoading(pageState.error);
        setApiError(error.message || t(`${i18Prefix}.api_failure`));
      }
    }

    updateTableData();
    return () => {
      canceled = true;
    };
  }, [currentLocation]);

  // TODO: fix select all
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const peopleList = [];
      filteredPeople.forEach((person) => {
        peopleList.push(person.name);
      });
      setSelected(peopleList);
      return;
    }
    setSelected([]);
  };

  // TODO: speed up this function
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    // NOTE: marks the item selected if not, not selected if is
    // NOTE: operated based on index in selected person list
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // TODO: extract these two functions and several components below (copies)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <FillVerticalBox data-testid={DeliveryPartnersTestId}>
      <AppSpacerBox />

      <PageHeader view={pages.deliveryPartners.title()} />

      <DataTableContainer
        style={
          {
            ...(pageLoading !== pageState.done && centerVertical),
            height: '93%',
          } as any
        }
      >
        {/* TODO: show over table data to smooth transitions */}
        {pageLoading === pageState.loading && (
          <CircularProgress
            size={60}
            style={{
              margin: 'auto',
            }}
          />
        )}
        {pageLoading === pageState.error && (
          <Card
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              color: colorPalette.statusRed,
            }}
          >
            {apiError}
          </Card>
        )}
        {pageLoading === pageState.done && (
          <StyledTable stickyHeader size='small'>
            <TableHead>
              <TableRow>
                {tempFeatureFlag && (
                  <DataTableHeader padding='checkbox'>
                    <DataTableCheckBox
                      indeterminate={selected.length > 0 && selected.length < filteredPeople.length}
                      checked={
                        filteredPeople.length > 0 && selected.length === filteredPeople.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select-all-people' }}
                    />
                  </DataTableHeader>
                )}
                <DataTableHeader>
                  <Grid container direction='row' justifyContent='left' alignItems='center'>
                    <Grid item>
                      <DataTableLeftText>{t(`${i18Prefix}.name`)}</DataTableLeftText>
                    </Grid>
                    <Grid item>
                      <Box color={colorPalette.gray5}>
                        <IconButton size='small' color='inherit'>
                          <SortIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.id`)}</DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.phone_number`)}</DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.email`)}</DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.position`)}</DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.last_shift_date`)}</DataTableHeader>
                <DataTableHeader>{t(`${i18Prefix}.locations`)}</DataTableHeader>
                {tempFeatureFlag && (
                  <DataTableHeader>
                    <DataTableRightText>{t(`${i18Prefix}.actions`)}</DataTableRightText>
                  </DataTableHeader>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO: add sorting support */}
              {filteredPeople?.length > 0 &&
                filteredPeople
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((person, index) => {
                    const isItemSelected = isSelected(`${person.firstName} ${person.lastName}`);
                    const labelId = `checkbox-${index}`;

                    return (
                      <TableRow
                        key={`${JSON.stringify(person)}-${index}`}
                        hover
                        onClick={(event) =>
                          handleClick(event, `${person.firstName} ${person.lastName}`)
                        }
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        {tempFeatureFlag && (
                          <DataTableCell
                            padding='checkbox'
                            style={{ borderRight: colorPalette.white }}
                          >
                            <DataTableCheckBox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </DataTableCell>
                        )}
                        <DataTableCell
                          id={labelId}
                          component='th'
                          align='left'
                          scope='row'
                          padding='none'
                          style={
                            {
                              borderLeft: colorPalette.white,
                              position: 'relative',
                              ...noWrap,
                            } as any
                          }
                        >
                          <DataTableLeftText style={{ ...noWrap, ...centerVertical } as any}>
                            {`${person.firstName} ${person.lastName}`}
                          </DataTableLeftText>
                        </DataTableCell>
                        <DataTableCell align='left'>{person.id}</DataTableCell>
                        <DataTableCell align='left'>{person.phoneNumber}</DataTableCell>
                        <DataTableCell align='left'>{person.email}</DataTableCell>
                        <DataTableCell align='left'>
                          {person.roles?.map((role) => role.name).join(',') ?? ''}
                        </DataTableCell>
                        <DataTableCell>
                          {person.lastShiftDate
                            ? formatSingleDay(
                                DateTime.fromISO(person.lastShiftDate),
                                currentLocation.timeZone,
                              )
                            : ''}
                        </DataTableCell>
                        <DataTableCell align='left'>
                          {person.locations?.map((location) => (
                            <Chip
                              key={`${JSON.stringify(location)}-${index}`}
                              size='small'
                              label={location.friendly_name || location.name}
                              style={{ margin: 2 }}
                            />
                          ))}
                        </DataTableCell>
                        {tempFeatureFlag && (
                          <DataTableCell align='left'>
                            <Box
                              style={{
                                marginRight: 7,
                              }}
                              color={colorPalette.gray5}
                            >
                              {/* TODO: add on-click */}
                              <IconButton size='small' color='inherit'>
                                <EventNoteIcon {...({} as any)} size='inherit' />
                              </IconButton>
                              {/* TODO: add on-click */}
                              <IconButton size='small' color='inherit'>
                                <DeleteIcon {...({} as any)} size='inherit' />
                              </IconButton>
                              <IconButton
                                size='small'
                                color='inherit'
                                onClick={() => {
                                  setDisplayModal({
                                    open: true,
                                    modal: modals.editDeliveryPartnerModal,
                                  });
                                }}
                              >
                                <CreateIcon {...({} as any)} size='inherit' />
                              </IconButton>
                            </Box>
                          </DataTableCell>
                        )}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </StyledTable>
        )}
      </DataTableContainer>
      <PaginationTableContainer>
        <Table size='medium'>
          <TableBody>
            <TableRow>
              {/* TODO: change display.  reduce distance to bottom of table */}
              <StyledTablePagination
                rowsPerPageOptions={paginationOptions}
                count={filteredPeople.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={`${t(`${i18Prefix}.rows_per_page`)}:`}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${t(`${i18Prefix}.of`)} ${count}`
                }
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={
                  {
                    'data-testid': paginationBackButtonTestId,
                  } as any
                }
                nextIconButtonProps={
                  {
                    'data-testid': paginationNextButtonTestId,
                  } as any
                }
              />
            </TableRow>
          </TableBody>
        </Table>
      </PaginationTableContainer>
    </FillVerticalBox>
  );
};

export { DeliveryPartnersTestId };
export default DeliveryPartners;
