import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useOktaAuth } from '@okta/okta-react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import i18n from 'i18next';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userAccess } from '../../utilities/AccessPermissions';
import { modalState } from '../../atoms/ModalState';
import {
  classificationState,
  colorSelector,
  currentLocationState,
  deliveryMethodState,
  peopleState,
  userActionState,
} from '../../atoms/QueryState';
import { i18Prefix } from '../../i18Constants';
import '../../i18n';
import { createShifts, deleteShifts, editShifts } from '../../services/shifts';
import {
  getDifferenceAsHoursVariableDisplayFull,
  isValidDateTime,
  setTimeInDay,
} from '../../utilities/Dates';
import {
  colorPalette,
  PrimaryActionButton,
  SecondaryActionButton,
  TertiaryActionButton,
  StyledTextField,
  renderCustomDayPicker,
  getDateTimePaperPropsStyles,
} from '../../utilities/Styles';
import { modals, pageState, tempFeatureFlag, userActions } from '../../utilities/Variables';
import { closeModalTestId } from '../../utilities/TestIds';
import { popperProps } from '../../utilities/Props';
import config from '../../config';
import { pageLoadingState } from '../../atoms/PageState';
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models/views';

const deliveryMethodSelectTestId = 'deliveryMethodSelect';
const deliveryMethodMenuTestId = 'deliveryMethodMenu';
const positionSelectTestId = 'positionSelect';
const positionMenuTestId = 'positionMenu';
const countTestId = 'count';
const saveTestId = 'save';
const savePublishTestId = 'savePublish';
const deleteTestId = 'delete';
const assignToTestId = 'assignTo';
const shiftStartDateTestId = 'shiftStartDate';
const shiftEndDateTestId = 'shiftEndDate';

const ShiftModalBody = (props) => {
  const { t } = useTranslation();
  const { authState } = useOktaAuth();

  const setPageLoading = useSetRecoilState(pageLoadingState);
  const [userAction, setUserAction] = useRecoilState(userActionState);
  const [displayModal, setDisplayModal] = useRecoilState(modalState);
  const colors = useRecoilState<string[]>(colorSelector as any)[0];
  const currentLocation = useRecoilState(currentLocationState)[0];

  const classifications = useRecoilValue(classificationState);
  const deliveryMethods = useRecoilValue(deliveryMethodState);
  const people = useRecoilValue(peopleState);

  const [hoursDuration, setHoursDuration] = useState('0 hours');
  const [startTimePickerOpen, setStartTimePickerOpen] = useState(false);
  const [endTimePickerOpen, setEndTimePickerOpen] = useState(false);
  const [timePickerView, setTimePickerView] = useState('hours');

  useEffect(() => {
    return () => {
      setStartTimePickerOpen(false);
      setEndTimePickerOpen(false);
    };
  }, []);

  const hasMustRequestAccess = (authState?.accessToken?.claims?.userType as any)?.some((type) =>
    userAccess.mustRequest.includes(type),
  );

  const peopleList = [
    {
      id: -1,
      firstName: t(`${i18Prefix}.unassigned`),
      lastName: '',
    },
    ...people,
  ];

  const getPersonById = (id) => {
    if (id) {
      const personById = peopleList.find((person) => id === person.id);
      return personById;
    }
    return null;
  };

  const startTime =
    displayModal.modalObject.shift?.startTime?.setZone(currentLocation.timeZone) || DateTime.now();
  const endTime =
    displayModal.modalObject.shift?.endTime || false
      ? displayModal.modalObject.shift?.endTime?.setZone(currentLocation.timeZone)
      : startTime;
  const assignTo = getPersonById(displayModal.modalObject.shift?.assignedPersonId ?? -1);

  const deliveryMethod =
    Object.values(deliveryMethods).find(
      (method) => method.id === displayModal.modalObject?.shift?.deliveryMethodId,
    )?.value || 'car';
  const position =
    Object.values(classifications).find(
      (classification) => classification.id === displayModal.modalObject?.shift?.position,
    )?.value || 'driver';

  const [formValues, setFormValues] = useState({
    startTime,
    lastValidStartTime: startTime,
    endTime,
    lastValidEndTime: endTime,
    assignTo,
    count: 1,
    position,
    deliveryMethod,
    mustRequest: displayModal.modalObject?.shift?.mustRequest ?? false,
  });

  useEffect(() => {
    setHoursDuration(
      getDifferenceAsHoursVariableDisplayFull(formValues.endTime, formValues.startTime, 1),
    );
  }, [formValues]);

  const [formErrors, setFormErrors] = useState({} as any);
  const [apiError, setApiError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  // NOTE: to enable unit testing.
  // TODO: remove props when edit/create check covered by mocked state
  const isEdit = displayModal.modal === modals.editModal;

  /* Check if any fields are not set or set incorrectly and return messages for any found errors */
  const validateInputsCreate = () => {
    const errors: any = {};
    if (formValues.startTime > formValues.endTime)
      errors.endTime = 'End time cannot be earlier than start time';
    if (!formValues.assignTo) errors.assignTo = 'Required field';
    if (!formValues.startTime) errors.startTime = 'Required field';
    if (!formValues.endTime) errors.endTime = 'Required field';
    if (!formValues.count) errors.count = 'Required field';
    if (parseInt(formValues.count as any, 10) < 1) errors.count = 'Count must be at least 1';
    if (parseInt(formValues.count as any, 10) > 1 && formValues.assignTo.id !== -1)
      errors.count = 'When creating multiple instances, shift must be left unassigned';
    return errors;
  };

  /* Check if any fields are not set or set incorrectly and return messages for any found errors */
  const validateInputsEdit = () => {
    const errors: any = {};
    if (formValues.startTime > formValues.endTime)
      errors.endTime = 'End time cannot be earlier than start time';
    if (!formValues.startTime) errors.startTime = 'Required field';
    if (!formValues.endTime) errors.endTime = 'Required field';
    return errors;
  };

  const validateDuration = (hours) => {
    const parsedHours = parseFloat(hours);
    if (parsedHours < 0 || parsedHours >= 12) {
      return false;
    }
    return true;
  };

  const clearState = () => {
    setLoading(false);
    setFormErrors({});
    setApiError(undefined);
  };

  const handleClose = () => {
    clearState();
    setDisplayModal({ open: false, modalObject: {}, modal: '' });
  };

  const handleChange = (e) => {
    formValues[e.target.name] = e.target.value;
    setFormValues({ ...formValues });
  };

  const resolveUpdatedTimeValue = (value, keyboardInputValue, date) => {
    const keyboardInputDate = setTimeInDay(
      DateTime.fromFormat(keyboardInputValue ?? '', getLocaleFormat()),
      date,
    );
    return isValidDateTime(keyboardInputDate) ? keyboardInputDate : value;
  };

  const handleStartTimeChange = (value, keyboardInputValue) => {
    formValues.startTime = resolveUpdatedTimeValue(
      value,
      keyboardInputValue,
      formValues.lastValidStartTime,
    );
    if (isValidDateTime(formValues.startTime)) {
      formValues.lastValidStartTime = formValues.startTime;
    }
    setFormValues({ ...formValues });
  };

  const handleEndTimeChange = (value, keyboardInputValue) => {
    formValues.endTime = resolveUpdatedTimeValue(
      value,
      keyboardInputValue,
      formValues.lastValidEndTime,
    );
    if (isValidDateTime(formValues.endTime)) {
      formValues.lastValidEndTime = formValues.endTime;
    }
    setFormValues({ ...formValues });
  };

  const getLocaleClock = () => {
    const localeDate = new Date();
    const localeString = localeDate.toLocaleString(i18n.language);
    if (localeString.toUpperCase().endsWith('AM') || localeString.toUpperCase().endsWith('PM')) {
      return true;
    }
    return false;
  };

  const getLocaleFormat = () => {
    const localeDate = new Date();
    const localeString = localeDate.toLocaleString(i18n.language);
    if (localeString.toUpperCase().endsWith('AM') || localeString.toUpperCase().endsWith('PM')) {
      return 'hh:mm a';
    }
    return 'HH:mm';
  };

  /* Handle click of 'Delete' button */
  const handleDelete = async () => {
    clearState();
    setLoading(true);
    try {
      const shiftToDelete = displayModal.modalObject.shift; // the shift object itself
      const shiftId = shiftToDelete.ids[0];

      const response = await deleteShifts([shiftId]);
      setLoading(false);

      if (!response || response[0].success === false) {
        setApiError(
          response?.[0]?.message ||
            'We are having trouble processing this request. Please contact support',
        );
      } else {
        // if entry succeeded, set userAction & close modal
        setPageLoading(pageState.loading);
        setUserAction({
          userAction: userActions.delete,
          actionCount: userAction.actionCount + 1,
        });
        handleClose();
      }
    } catch (err) {
      // catch and display API responses other then 200
      setLoading(false);
      console.log(err);
      setApiError(
        err.message || 'We are having trouble processing this request. Please contact support',
      );
    }
  };

  /* Handle click of either 'Save' or 'Save & Publish' buttons */
  const handleSave = async (options) => {
    clearState();
    setLoading(true);
    let errors;
    if (isEdit) {
      errors = validateInputsEdit(); // check for errors if editing
    } else {
      errors = validateInputsCreate(); // check for errors if creating
    }
    if (Object.keys(errors).length > 0) {
      // display messages if errors found
      setLoading(false);
      setFormErrors(errors);
    } else {
      try {
        let response;
        const { publish } = options;
        const now = DateTime.now().toUTC().toISO();
        if (!isEdit) {
          const count = parseInt(formValues.count as any, 10);

          // create an array of multiple identical shift entries if count > 1
          const shifts = Array.from({ length: count }, () => ({
            startTime: formValues.startTime
              .setZone(currentLocation.timeZone, { keepLocalTime: true })
              .toISO(),
            endTime: formValues.endTime
              .setZone(currentLocation.timeZone, { keepLocalTime: true })
              .toISO(),
            isPublished: publish,
            publishedTime: publish ? now : null,
            locationId: currentLocation.id,
            deliveryMethodId: deliveryMethods[formValues.deliveryMethod]?.id,
            // if user selected 'Unassigned', leave this field undefined
            assignedPersonId: formValues.assignTo.id === -1 ? null : formValues.assignTo.id,
            mustRequest: formValues.mustRequest,
            /**
             * TODO: uncomment when colors, positions are fetched from the API
             * shiftRoleId:formValues.position,
             * tags:[
             *  { color: formValues.color }
             * ],
             */
          }));
          response = await createShifts(shifts);
          setLoading(false);
        } else {
          const shiftToEdit = displayModal.modalObject.shift; // the shift object itself
          // create the shift we are editing
          const shift: any = {
            id: shiftToEdit.ids[0],
            startTime: formValues.startTime,
            endTime: formValues.endTime,
            // if user selected 'Unassigned', it is being unassigned, so null
            assignedPersonId: formValues.assignTo.id === -1 ? null : formValues.assignTo.id,
            deliveryMethodId: deliveryMethods[formValues.deliveryMethod]?.id,
            mustRequest: formValues.mustRequest,
            locationId: currentLocation.id,
            /**
             * TODO: uncomment when colors, positions are fetched from the API
             * shiftRoleId:formValues.position,
             * tags:[
             *  { color: formValues.color }
             * ],
             */
          };

          if (publish) {
            shift.isPublished = publish;
            shift.publishedTime = publish ? now : null;
          }

          response = await editShifts([shift]);
          setLoading(false);
        }

        /* since all entries are identical, and edit shift is just one, if the first entry failed
        assume others will fail as well and display message */
        if (!response[0] || response[0].success === false) {
          setApiError(
            response[0]?.message ||
              'We are having trouble processing this request. Please contact support',
          );
        } else {
          // if first entry succeeded, assume others succeeded as well and close modal
          setPageLoading(pageState.loading);
          if (isEdit) {
            setUserAction(
              publish
                ? {
                    userAction: userActions.editAndPublish,
                    actionCount: userAction.actionCount + 1,
                  }
                : {
                    userAction: userActions.editAndSave,
                    actionCount: userAction.actionCount + 1,
                  },
            );
          } else {
            setUserAction(
              publish
                ? {
                    userAction: userActions.createAndPublish,
                    actionCount: userAction.actionCount + 1,
                  }
                : {
                    userAction: userActions.createAndSave,
                    actionCount: userAction.actionCount + 1,
                  },
            );
          }
          handleClose();
        }
      } catch (err) {
        // catch and display API responses other then 200
        setLoading(false);
        console.log(err);
        setApiError(
          err.message || 'We are having trouble processing this request. Please contact support',
        );
      }
    }
  };

  const renderError = () =>
    apiError || Object.keys(formErrors).length ? (
      <DialogContent style={{ color: 'red' }}>
        {apiError || Object.values(formErrors)[0]}
      </DialogContent>
    ) : null;

  const renderLoading = () => (loading ? <CircularProgress style={{ margin: 'auto' }} /> : null);

  const setDateTimePickerView = (pickerView: CalendarOrClockPickerView) => {
    setTimePickerView(pickerView);
  };

  const openDateTimePicker = (isStartTime: boolean) => {
    isStartTime ? setStartTimePickerOpen(true) : setEndTimePickerOpen(true);
    setTimePickerView('hours');
  };

  const closeDateTimePicker = (isStartTime) => {
    isStartTime ? setStartTimePickerOpen(false) : setEndTimePickerOpen(false);
    setTimePickerView('hours');
  };

  // Only used when unit testing to enable defaulting the modal to open
  const { open } = props;

  // TO DO: Add date to modal title, weekday, month number
  return (
    <div>
      <Dialog
        open={open || displayModal.open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title' style={{ backgroundColor: colorPalette.gray2 }}>
          {isEdit ? t(`${i18Prefix}.edit_hours`) : t(`${i18Prefix}.create_hours`)}
          <Button
            onClick={handleClose}
            data-testid={closeModalTestId}
            style={{
              float: 'right',
              padding: '2px',
              margin: '0px',
            }}
          >
            <Close
              style={{
                fontSize: '26px',
              }}
            />
          </Button>
          <Grid
            item
            style={{
              float: 'right',
              color: validateDuration(hoursDuration) ? colorPalette.gray7 : colorPalette.warningRed,
              fontWeight: 700,
              fontSize: 18,
              padding: 1,
            }}
          >
            {hoursDuration}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form style={{ paddingTop: 10 }}>
            <Autocomplete
              data-testid={assignToTestId}
              disableClearable
              {...{ variant: 'outlined' }}
              options={peopleList}
              value={formValues.assignTo}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`.trim()}
              onChange={(event, value) => {
                formValues.assignTo = value;
                setFormValues({ ...formValues });
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {`${option.firstName} ${option.lastName}`.trim()}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='assignTo'
                  label={t(`${i18Prefix}.assign_to`)}
                  variant='outlined'
                  margin='dense'
                  style={{ width: '316px' }}
                  error={formErrors.assignTo ? true : null}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: 0,
                      paddingLeft: 5,
                    },
                  }}
                />
              )}
            />
            {/* TODO: add minDate */}
            <div style={{ paddingBottom: 15 }} />
            <Box display='flex'>
              <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
                <DateTimePicker
                  disableMaskedInput={false}
                  open={startTimePickerOpen}
                  onOpen={() => openDateTimePicker(true)}
                  onClose={() => closeDateTimePicker(true)}
                  ampm={getLocaleClock()}
                  inputFormat={getLocaleFormat()}
                  label={t(`${i18Prefix}.start_time`)}
                  openTo={'hours'}
                  value={formValues.startTime?.set({ second: 0, millisecond: 0 }) || null}
                  onChange={handleStartTimeChange}
                  onViewChange={setDateTimePickerView}
                  showToolbar={true}
                  renderDay={renderCustomDayPicker}
                  renderInput={(params) => (
                    <StyledTextField
                      style={{ width: 150, marginTop: 8 }}
                      {...params}
                      data-testid={shiftStartDateTestId}
                    />
                  )}
                  PopperProps={popperProps}
                  PaperProps={getDateTimePaperPropsStyles(timePickerView)}
                />
                <DateTimePicker
                  disableMaskedInput={false}
                  open={endTimePickerOpen}
                  onOpen={() => openDateTimePicker(false)}
                  onClose={() => closeDateTimePicker(false)}
                  ampm={getLocaleClock()}
                  inputFormat={getLocaleFormat()}
                  label={t(`${i18Prefix}.end_time`)}
                  openTo={'hours'}
                  value={formValues.endTime?.set({ second: 0, millisecond: 0 }) || null}
                  onChange={handleEndTimeChange}
                  onViewChange={setDateTimePickerView}
                  showToolbar={true}
                  renderDay={renderCustomDayPicker}
                  renderInput={(params) => (
                    <StyledTextField
                      style={{ width: 150, marginLeft: 16, marginTop: 8 }}
                      {...params}
                      data-testid={shiftEndDateTestId}
                    />
                  )}
                  PopperProps={popperProps}
                  PaperProps={getDateTimePaperPropsStyles(timePickerView)}
                />
              </LocalizationProvider>
              {!isEdit && (
                <StyledTextField
                  data-testid={countTestId}
                  name='count'
                  label={t(`${i18Prefix}.count`)}
                  type='number'
                  inputProps={{ min: 1 }}
                  value={formValues.count}
                  style={{ width: 112, marginLeft: 16, marginTop: 8 }}
                  error={formErrors.count ? true : null}
                  onChange={handleChange}
                />
              )}
            </Box>
            <div style={{ paddingBottom: 15 }} />
            <Box display='flex'>
              <StyledTextField
                name='deliveryMethod'
                label={t(`${i18Prefix}.delivery_method`)}
                select
                SelectProps={{
                  SelectDisplayProps: {
                    'data-testid': deliveryMethodSelectTestId,
                  } as any,
                  MenuProps: {
                    'data-testid': deliveryMethodMenuTestId,
                  } as any,
                }}
                value={formValues.deliveryMethod}
                style={{ width: '150px' }}
                error={formErrors.deliveryMethod ? true : null}
                helperText={formErrors.deliveryMethod || null}
                onChange={handleChange}
              >
                {Object.keys(deliveryMethods)
                  .filter((key) => config.DISPLAY_MOPED || !key.includes('moped'))
                  .map((method) => (
                    <MenuItem key={deliveryMethods[method].value} value={method}>
                      {deliveryMethods[method].name()}
                    </MenuItem>
                  ))}
              </StyledTextField>

              <StyledTextField
                data-testid='position'
                name='position'
                label={t(`${i18Prefix}.position`)}
                select
                SelectProps={{
                  SelectDisplayProps: {
                    'data-testid': positionSelectTestId,
                  } as any,
                  MenuProps: {
                    'data-testid': positionMenuTestId,
                  } as any,
                }}
                value={formValues.position}
                style={{ width: '150px', marginLeft: '16px' }}
                error={formErrors.position ? true : null}
                helperText={formErrors.position || null}
                onChange={handleChange}
              >
                {Object.keys(classifications).map((classification) => (
                  <MenuItem key={classifications[classification].value} value={classification}>
                    {classifications[classification].name()}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Box>
            {hasMustRequestAccess &&
              (currentLocation as any).shiftRequestEnabled &&
              formValues.assignTo?.id === -1 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={formValues.mustRequest}
                      onChange={(e) =>
                        setFormValues({ ...formValues, mustRequest: e.target.checked })
                      }
                      name='driverMustRequest'
                    />
                  }
                  label={t(`${i18Prefix}.driver_must_request`)}
                />
              )}
            {tempFeatureFlag && (
              <TextField
                name='color'
                label='Color'
                select
                variant='outlined'
                margin='dense'
                style={{ width: '150px', marginLeft: '16px' }}
                error={formErrors.color ? true : null}
                helperText={formErrors.color || null}
                onChange={handleChange}
              >
                {colors.map((color) => (
                  <MenuItem key={color} value={color}>
                    {color}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </form>
        </DialogContent>
        {renderLoading() || renderError()}
        <DialogActions>
          {!isEdit ? null : (
            <SecondaryActionButton
              onClick={() => handleDelete()}
              style={{ marginRight: '90px' }}
              data-testid={deleteTestId}
            >
              {t(`${i18Prefix}.delete`)}
            </SecondaryActionButton>
          )}
          <TertiaryActionButton
            onClick={() => handleSave({ publish: true })}
            color='primary'
            data-testid={savePublishTestId}
          >
            {t(`${i18Prefix}.save_publish`)}
          </TertiaryActionButton>
          <PrimaryActionButton
            data-testid={saveTestId}
            onClick={() => handleSave({ publish: false })}
            color='primary'
          >
            {t(`${i18Prefix}.save`)}
          </PrimaryActionButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export {
  deliveryMethodSelectTestId,
  deliveryMethodMenuTestId,
  positionSelectTestId,
  positionMenuTestId,
  countTestId,
  saveTestId,
  assignToTestId,
};
export default ShiftModalBody;
