export default {
  DRIVER_SCHEDULING_API:
    process.env.REACT_APP_DRIVER_SCHEDULING_API || 'https://api.gopuff.tech/driver-scheduling',
  OKTA: {
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: process.env.REACT_APP_OKTA_REDIRECT,
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: true,
  },
  DISPLAY_MOPED: JSON.parse(process.env.REACT_APP_DISPLAY_MOPED || 'false'),
};
